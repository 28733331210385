import { inject, observer } from "mobx-react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const GooglePayAdvanceOptions = (props) => {
  const isGooglePayEnabled = props.store.getGooglePayEnabled;
  const autoPayEnabled = props.store.paymentPage.autopay.enabled;
  const hasMethod = props.store.paymentPage.paymentMethods.methods.googlePay;
  return (
    <div className="col">
      {!isGooglePayEnabled ? (
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip>
              {
                "This service is not activated for the paypoint to which this customer belongs"
              }
            </Tooltip>
          }
        >
          <div className="icheck-primary">
            <input
              disabled={!isGooglePayEnabled}
              checked={false}
              type="checkbox"
              id="googlePay"
            />
            <label htmlFor="googlePay">Google Pay</label>
          </div>
        </OverlayTrigger>
      ) : autoPayEnabled ? (
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip>{"Must deactivate autopay to enable Google Pay"}</Tooltip>
          }
        >
          <div className="icheck-primary">
            <input
              disabled={!isGooglePayEnabled}
              checked={false}
              type="checkbox"
              onChange={() => {}}
              id="googlePay"
            />
            <label htmlFor="googlePay">Google Pay</label>
          </div>
        </OverlayTrigger>
      ) : (
        <div className="icheck-primary">
          <input
            checked={hasMethod && !autoPayEnabled}
            onChange={props.handlePaymentMethods}
            type="checkbox"
            id="googlePay"
          />
          <label htmlFor="googlePay">Google Pay</label>
        </div>
      )}
    </div>
  );
};

export default inject('store', 'global', 'invoice')(observer(GooglePayAdvanceOptions));
