import React from "react";
import { inject, observer } from "mobx-react";

const ApplePayButtonType = (props) => {
  function handleTextChangeSelect(e) {
    const selectedValue = e.target.value;
    props.store.setApplePayButton({ buttonStyle: selectedValue });
  }
  return (
    <div>
        <div className="col-sm mb-3">
          <div className="form-floating">
            <select
              className="form-control"
              style={{ appearance: 'none'}}
              id="appleButtonStyle"
              name="appleButtonStyle"
              onChange={handleTextChangeSelect}
              value={
                props.store.paymentPage.paymentMethods.settings.applePay
                  .buttonStyle
              }
              data-qaid="appleButtonStyleInput"
            >
              {props.applepay.applePayButtonStyles.map((style) => (
                <option
                  key={style.value}
                  value={style.value}
                  title={style.description}
                >
                  {style.text}
                </option>
              ))}
            </select>
            <label htmlFor="appleButtonStyle">Style</label>
          </div>
        </div>
      </div>
  )
}

export default inject(
  "store",
  "applepay"
)(observer(ApplePayButtonType));
