import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import { ReportsLinks } from '../../components/ReportsLinks';
import { ReportPaginator } from '../../components/ReportPaginator';
import { ParentFilterPanel } from '../../components/filters/ParentFilterPanel';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { BiNotepad, BiUnlink } from 'react-icons/bi';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import { Layout } from '../../components/Layout'
import { Link } from 'react-router-dom';
import { PayabliStorageManager } from '../../api/localStorageManager';
import { RecordsNotFound } from '../../components/RecordsNotFound';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { RightPanel } from '../../components/RightPanel';
import { ModalImport } from '../Common/ModalImport';
import { MainBar } from '../../components/MainBar';
import { BiSortAlt2, BiDotsVerticalRounded } from 'react-icons/bi';
import { CgUnavailable } from 'react-icons/cg';

@inject('reports', 'global', 'chargeback')
@observer
class ChargebacksReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            customFields: [],
            paymentTransId: null,
            modalImportIsOpen: false,
            flagItemAllColumn: true,
            sortDirection: "desc",
        };
        this.handleShowColumn = this.handleShowColumn.bind(this);
        this.exportFile = this.exportFile.bind(this);
        this.filter = this.filter.bind(this);
        this.showHideFilters = this.showHideFilters.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.getReportFromApi = this.getReportFromApi.bind(this);        
        this.viewRecordDetails = this.viewRecordDetails.bind(this);
        this.getCustomFieldsFromApi = this.getCustomFieldsFromApi.bind(this);
        this.handleQuickFilter = this.handleQuickFilter.bind(this);
        this.showTransactionDetails = this.showTransactionDetails.bind(this);
        this.toggleModalImport = this.toggleModalImport.bind(this);
        this.handleImportAction = this.handleImportAction.bind(this);   
        this.handlerImportClick = this.handlerImportClick.bind(this);   
        this.selectAllColumns = this.selectAllColumns.bind(this);
        this.sortBy = this.sortBy.bind(this);
    }

    toggleModalImport(){
        this.setState({modalImportIsOpen: false})
    }

    handleImportAction(){}

    toggleModalMarkPaid(recordIdSelected) {
        this.setState({
            recordIdSelected: recordIdSelected,
            markPaidModalIsOpen: !this.state.markPaidModalIsOpen,
        });
    }
    
    handlerImportClick(ev){
        this.setState({modalImportIsOpen: true})
    }

    showTransactionDetails(record, object){
        if(object){
            object.preventDefault();
        }
        this.setState({transactionDetail: record.Transaction ? record.Transaction : null, chargebackDetails: record});
        this.props.global.setRightPanelOpen(true);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.location.pathname !==prevProps.location.pathname){
            window.location.href = "/report/chargebacks"
        }
    }
    
    componentDidMount() {
        this.props.global.protect(this.props.history);
        this.props.reports.setFrom(0);
        this.props.global.setLoading(true);
        this.setState({ 
            paymentTransId: this.props.match.params.paymentTransId ? this.props.match.params.paymentTransId : null
        });
        this.props.reports.setHeaders(
            {
                paymentId       : { label:'Payment ID', class: '', display: false}, 
                paypointDbaname : { label:'Paypoint DBA', class: '', display: false},
                customer        : { label:'Customer', class: '', display: true},
                customerNumber  : { label:'Customer #', class: '', display: false},
                payor           : { label:'Payor', class: '', display: true},                 
                billingAddress  : { label:'Billing Address', class: '', display: false},
                shippingAddress : { label:'Shipping Address', class: '', display: false},
                invoiceNumber   : { label:'Invoice #', class: '', display: false},
                chargebackDate  : { label:'Reported Date', class: '', display: true},
                createdAt       : { label:'Date Logged', class: '', display: false},
                replyDate       : { label:'Reply By', class: '', display: true},
                caseNumber      : { label:'Case #', class: '', display: true}, 
                reasonCode      : { label:'Reason Code', class: '', display: false}, 
                reason          : { label:'Reason', class: '', display: true},
                referenceNumber : { label:'Reference #', class: '', display: false},
                lastFour        : { label:'Last 4', class: '', display: false},
                accountType     : { label:'Pay Method', class: 'text-center', display: true},
                netAmount       : { label:'Net Amount', class: 'text-right', display: true},
                currency        : { label:'Currency', class: 'text-center', display: false}, 
                transactionTime : { label:'Transaction Time (' + this.props.global.getTimeZone('v1') + ')', class: '', display: false},
                cycle           : { label:'Cycle', class: '', display: false},
                status          : { label:'Status', class: '', display: true},
                notes           : { label:'Notes', class: 'text-center', display: true}                
            }
        )
        this.getCustomFieldsFromApi();
        this.props.reports.setRecords([]);
        this.clearFilters();
    }

    selectAllColumns(e, containerId){
        let menuContainer = document.querySelector('[aria-labelledby="'+containerId+'"]');
        let checkBoxes = menuContainer.getElementsByTagName('input');
        for(var i = 0; i < checkBoxes.length; i++) {
            if(checkBoxes[i].type.toLowerCase() === 'checkbox' && checkBoxes[i].checked === !e.target.checked) {
                checkBoxes[i].click();
            }
        }
        this.setState({flagItemAllColumn: false});
    }

    getCustomFieldsFromApi(){
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let entry = encryptStorage && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`) && 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry ? 
            encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry : 0;
        let reactObj = this;

        this.props.reports.getEntrySettings(entry).then(res => {
            if(res.data.customFields){
                let fields = res.data.customFields;
                fields.forEach(function (item, index) {
                    reactObj.props.reports.addHeader(item.key ,{ label:item.key, class: '', display: true, filter: item.key, custom: true});
                });
                this.setState({customFields:fields});
            }
        })
        .catch(error => {
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    handleShowColumn(event){
        this.props.reports.handleShowColumn(event);
        if (!this.state.flagItemAllColumn) {
            this.setState({flagItemAllColumn: true});
        }
    }

    viewRecordDetails(url, object){
        if(object && (this.props.global.isDrag() || object.target.rel === "noreferrer" || object.target.tagName.toLowerCase() === "svg" || object.target.tagName.toLowerCase() === "a" || object.target.tagName.toLowerCase() === "path")){
            return;
        }
        if(object?.target?.id !== "actionsMenuButton"){
            this.props.history.push(url);
        }
    }
    
    exportFile(format){
        this.props.reports.exportFile('chargebacks',format);
    }

    showHideFilters(){
        this.props.global.setFilterRightPanelOpen(true);
    }

    clearFilters(){
        this.setState(
            { head: "" }, () => {
                this.props.reports.clearFilters();
                this.getReportFromApi();
            }
        );
    }

    getMappingFields(){
        return {
            'paypointDbaname': 'paypointdba',
            'parentOrgName': 'orgname',
            'accountType': 'method',
            'customer': 'customefullname',
            'customerNumber': 'customernumber',
            'status': 'status',
            'paypointLegalname': 'paypointlegal',
            'invoiceNumber': 'invoicenumber',
            'chargebackDate': 'chargebackdate',
            'createdAt': 'createdat',
            'billingAddress': 'customeraddress',
            'reasonCode': 'reasoncode',
            'referenceNumber': 'referencenumber',
            'lastFour': 'lastfour',
            'netAmount': 'netAmount',
            'shippingAddress': 'customershippingaddress',
            'cycle': 'leftcycles',
            'caseNumber': 'casenumber',
            'reason': 'reason',
            'transactionTime': 'transactiontime',
            // 'payor': 'holdername',
            'paymentId': 'transid',
        }
    }
    middlwareFields(field) {
        const apiFields = this.getMappingFields();
        return apiFields[field];
    }
    sortBy(e, record){
        this.props.global.setLoading(true);
        this.setState({ sortBy: this.middlwareFields(record[0]) || '', sortDirection: this.state.sortDirection === 'asc' ? 'desc' : 'asc' }, () => {
            this.getReportFromApi();
        });
    }
    getReportFromApi(){
        let tempFilterValue = {};
        if(this.state.sortBy){
            tempFilterValue['sortBy'] = `${this.state.sortDirection}(${this.state.sortBy})`;
        }
        if(this.state.paymentTransId){
            tempFilterValue['transId(eq)'] = this.state.paymentTransId;
        }
        this.filter(tempFilterValue);
    }

    filter(type, value){
        this.props.global.setLoading(true);
        this.props.reports.filter(type,value, 'chargebacks').then(res => {
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    handleQuickFilter(value, header){
        this.filter('method(eq)',value)
        this.setState({ head: header });
    }
render() {
        let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
        let userPermissions = encryptStorage && encryptStorage.getItem('pUser') && encryptStorage.getItem('pUser').permissions ? encryptStorage.getItem('pUser').permissions : [];;
        let recordDetail = this.state.transactionDetail ? this.state.transactionDetail: null;
        let chargebackDetails = this.state.chargebackDetails ? this.state.chargebackDetails: null;
        return (
            <Layout {...this.props}>
            <div>
                
                <RightPanel>
                    <h5 className="header mb-3">Transaction Details</h5>

                    <div className="small mb-5">
                        <div className="row">
                            <div className="col-4">
                                <label className="header">Date & Time {"("+this.props.global.getTimeZone('v1')+")"}</label>
                            </div>
                            <div className="col-8">
                                {recordDetail ? this.props.global.stringDateFormat(recordDetail.TransactionTime) : '-'}
                                -
                                {recordDetail ? this.props.global.stringTimeFormat(recordDetail.TransactionTime) : '-'}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <label className="header">Type</label>
                            </div>
                            <div className="col-8">
                                {recordDetail ? recordDetail.Operation : '-'}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <label className="header">Status</label>
                            </div>
                            <div className="col-8">
                                {this.props.global.getTransStatusText(recordDetail?.TransStatus)}
                            </div>
                            <div className="col-4">
                                <label className="header">Batch Status</label>
                            </div>
                            <div className="col-8">
                                {(recordDetail && recordDetail.SettlementStatus !== null) ?
                                    <>
                                    {
                                        recordDetail.SettlementStatus < 0 ?
                                            <a href={"/report/chargebacks/"+recordDetail.PaymentTransId} >{this.props.global.getSettlementTransStatus(recordDetail.SettlementStatus, recordDetail.Method)}</a>
                                            :
                                            this.props.global.getSettlementTransStatus(recordDetail.SettlementStatus, recordDetail.Method)
                                    }
                                    </>
                                    : 
                                    <span className="badge bg-light"><CgUnavailable /> N/A</span>
                                }
                                
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <label className="header">Response</label>
                            </div>
                            <div className="col-8">
                                {(recordDetail && recordDetail.ResponseData) ? this.props.global.capitalizeFirstLetter(recordDetail.ResponseData.responsetext): '-'}
                                {(recordDetail && recordDetail.ResponseData) ? this.props.global.getIconTransactionResponse(recordDetail.ResponseData.response): ''}
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-4">
                               
                            </div>
                            <div className="col-8">
                                {(recordDetail && recordDetail.ResponseData) ? this.props.global.capitalizeFirstLetter(recordDetail.ResponseData.response_code_text): ''}
                            </div>
                        </div>

                        <div className="row mb-4">
                            {(recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.orderDescription) &&
                                <div className="col-12">
                                <label className="header">Notes:</label>
                                <p>
                                    {recordDetail.PaymentData.orderDescription}
                                </p>
                                </div>
                            }
                            {(recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.paymentDetails && recordDetail.PaymentData.paymentDetails.categories) &&
                            <div className="col-12">
                                {recordDetail.PaymentData.paymentDetails.categories.map((record, i) => (
                                    <div className="row mb-1" key={i}>
                                        <div className="col-4">{record.label}</div>
                                        <div className="col-3">${this.props.global.numberWithCommas((record.amount).toFixed(2))}</div>
                                        <div className="col-2">x {record.qty ? record.qty : 1}</div>
                                        <div className="col-3 text-right">${this.props.global.numberWithCommas(((record.qty ? record.qty : 1) * record.amount).toFixed(2))}</div>
                                    </div>
                                ))
                                }
                            </div>
                            }

                            <div className="col-12">
                                <div className="row mb-1">
                                    <div className="col-4">
                                        <b>Amount</b>
                                    </div>
                                    <div className="col-8 text-right">
                                        {(recordDetail && recordDetail.NetAmount) ? this.props.global.numberWithCommasNegative(recordDetail.NetAmount.toFixed(2)) : '$0.00'}
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-4">
                                        <b>Fee</b>
                                    </div>
                                    <div className="col-8 text-right">
                                        {(recordDetail && recordDetail.FeeAmount) ? this.props.global.numberWithCommasNegative(recordDetail.FeeAmount.toFixed(2)) : '$0.00'}
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-4">
                                        <b>Total</b>
                                    </div>
                                    <div className="col-8 text-right">
                                    <b>{(recordDetail && recordDetail.TotalAmount) ? this.props.global.numberWithCommasNegative(recordDetail.TotalAmount.toFixed(2)) : '$0.00'}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <h5 className="header mb-3">Payment Information</h5>
                        {(recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.MaskedAccount && recordDetail.PaymentData.MaskedAccount.toLowerCase() === "poi") ?
                            <div className="card-v2 mb-3" style={{padding: "3em"}}>
                                <div className="row">
                                <div className="col-7">
                                    <label className="grey">Source</label>
                                    <h6 style={{fontWeight: 500}}>
                                        POI Device
                                    </h6>
                                </div>
                                <div className="col-5 text-right">
                                    {this.props.global.getGlobalImg('pointofsale', '30px')}
                                </div>
                                </div>
                            </div>
                        :
                        <div className="card-v2 mb-4" style={{padding: "3em"}}>

                            <div className="right-panel-card mb-4">
                                {this.props.global.getPaymethodImgPaymentData(recordDetail)}
                            </div>
                       
                            <div className="row mb-2">
                                <div className="col-7">
                                    <label className="grey">{recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "card" ? "Card Number" : "Account Number" } </label>
                                    <h5 style={{fontWeight: 500}}>
                                    •••• •••• {chargebackDetails && chargebackDetails.LastFour ? this.props.global.maskedCardNumber(chargebackDetails.LastFour, 'v5'): '-'}
                                    </h5>
                                </div>
                                {recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "card" ?
                                <div className="col-5 text-center">
                                    <label className="grey">Expires on</label>
                                    <h5 style={{fontWeight: 500}}>
                                        {recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.AccountExp ? this.props.global.expDateFormat(recordDetail.PaymentData.AccountExp) : '-'}
                                    </h5>
                                </div>
                                :
                                <div className="col-5 text-center">
                                    <label className="grey">Account Type</label>
                                    <h5 style={{fontWeight: 500}}>
                                        {recordDetail && recordDetail.PaymentData && recordDetail.PaymentData.AccountType ? recordDetail.PaymentData.AccountType  : '-'}
                                    </h5>
                                </div>
                                }
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <label className="grey">{recordDetail && recordDetail.Method && recordDetail.Method.toLowerCase() === "card" ? "Cardholder Name" : "Account Holder Name" } </label>
                                    <h6 style={{fontWeight: 500}}>
                                        {(recordDetail && recordDetail.PaymentData )?  recordDetail.PaymentData.HolderName : '-'}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        }
                        <h5 className="header mb-3">Customer Information</h5>
                        <div className="row mb-4">
                            <div className="col-3">
                                <label className="header">Customer</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.Customer )? (recordDetail.Customer.FirstName ? this.props.global.capitalizeFirstLetterOfEachWord(recordDetail.Customer.FirstName) : "") + ' ' + (recordDetail.Customer.LastName ? this.props.global.capitalizeFirstLetterOfEachWord(recordDetail.Customer.LastName) : "") : '-'}
                            </div>
                        </div>

                        <h5 className="header mb-3">Processing Information</h5>
                        <div className="row mb-4">
                            <div className="col-3">
                                <label className="header">Transaction #</label>
                            </div>
                            <div className="col-9">
                            {(recordDetail && recordDetail.ResponseData ) ? recordDetail.ResponseData.transactionid : '-'}
                            </div>

                            <div className="col-3">
                                <label className="header">Payment ID</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.PaymentTransId !== null) ?  recordDetail.PaymentTransId : '-'}
                            </div>

                            <div className="col-3">
                                <label className="header">CVV Response</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.cvvresponse_text) ?  recordDetail.ResponseData.cvvresponse_text : '-'}
                            </div>

                            <div className="col-3">
                                <label className="header">AVS Response</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.avsresponse_text) ?  recordDetail.ResponseData.avsresponse_text : '-'}
                            </div>
                            <div className="col-3">
                                <label className="header">Auth Code</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.authcode) ?  recordDetail.ResponseData.authcode : '-'}
                            </div>
                            



                            <div className="col-3">
                                <label className="header">Source</label>
                            </div>
                            <div className="col-9">
                            {(recordDetail && recordDetail.Source ) ? this.props.global.capitalizeFirstLetterOfEachWord(recordDetail.Source) : '-'}
                            </div>
                        </div>

                        {(recordDetail && recordDetail.TransactionEvents && recordDetail.TransactionEvents.length > 0) &&
                        <>
                        <h5 className="header mb-3">Transaction Event History</h5>
                        <div className='timeLineRecordContainer'>
                        {recordDetail.TransactionEvents.map((record, i) => (
                            <div key={i} className='timeLineRecordItem'>
                                <div className="timeLineRecord">&nbsp;</div>
                                <div className="timeLineRecordText"><b>{record.TransEvent}</b><br/>{this.props.global.stringDateFormat(record.EventTime)} <span className="grey">{this.props.global.stringTimeFormat(record.EventTime)} {"("+this.props.global.getTimeZone('v1')+")"}</span></div>
                                <br/>
                            </div>
                        ))}
                        </div>
                        </>
                        }


                    </div>
                </RightPanel>
                <TopBar>
                <MainTopBarMenu/>
                <div className="top-bar-sub">
                    <ReportsLinks selected="chargebacks" history={this.props.history}/>
                </div>
                </TopBar>
                <ModalImport
                    isOpen={this.state.modalImportIsOpen}
                    closeModal={this.toggleModalImport}
                    action={this.handleImportAction}
                    modalType='ChargeBack'
                    textHeader="Import ChargeBack Data"
                    initialState={{uploadedFiles: [],correctFiles: []}}
                />
                <ParentFilterPanel report={'chargebacks'} customFields={this.state.customFields} />
                <div className="mt-body4">
                    <MainBar
                        reportTitle={this.state.head ? this.state.head : "ACH Returns & Chargebacks"}
                        reportName="achReturnsChargebacks"
                        dataQAName="ACHReturnsChargebacksMoneyIn"
                        showHideFilters={this.showHideFilters}
                        selectAllColumns={(e) => this.selectAllColumns(e, "columnReport")}
                        handleShowColumn={(e) => this.handleShowColumn(e)}
                        totalRecords={this.props.reports.totalRecords}
                        getHeaders={this.props.reports.getHeaders}
                        headers={this.props.reports.headers}
                        refreshView={this.clearFilters}
                        buttonExport={true}
                        searchBar={false}
                        exportFile={this.exportFile}
                        buttonImport={true}
                        importFile={this.handlerImportClick}
                        withTotal={true}
                        skipTitle={true}
                        flagItemAllColumn={this.state.flagItemAllColumn}
                        totalAmount={this.props.reports.totalAmount ? this.props.global.numberWithCommas(this.props.reports.totalAmount.toFixed(2)) : '0.00'}
                        balanceLine={true}
                    /> 
        
                    <div className="report-container">
                        <table className="table table-hover table-striped">
                            <thead>
                            <tr>
                            {this.props.reports.getHeaders.map((record, i) => (
                                    (Object.entries(this.getMappingFields()).flat(1).includes(record[0]) &&
                                    record[1].display && 
                                    (<th key={i} scope="col" className={`${record[1].class} sortby-parent`}>{record[1].label}
                                        <BiSortAlt2 className='cursorPointer sort-icon' onClick={(e) => this.sortBy(e, record)}/>
                                    </th> ))
                                    ||
                                    (record[1].display && 
                                    (<th key={i} scope="col" className={record[1].class}>{record[1].label}
                                    </th> ))
                                ))
                            }
                            <th className='text-center sticky-row' scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>                        
                        { this.props.reports.records.map((record, i) => (
                            <tr key={i} className="cursorPointer" onMouseUp={(e)=>this.props.global.setCoordClickUp(e)} onMouseDown={(e)=>this.props.global.setCoordClickDown(e)} onClick={(e) => this.viewRecordDetails("/"+PayabliStorageManager.getEntryName()+"/chargeback/"+ record.Id,e)} data-qaid={"chargebacksMoneyInRow-"+(i)}>                                
                                { (this.props.reports.headers.paymentId && this.props.reports.headers.paymentId.display) &&
                                    <td>{record.PaymentTransId ? record.PaymentTransId.substring(0, 20) : ''}...</td>
                                }
                                { (this.props.reports.headers.paypointDbaname && this.props.reports.headers.paypointDbaname.display) &&
                                    <td>{this.props.global.capitalizeFirstLetterOfEachWord(record.PaypointDbaname)}</td>
                                }                            
                                { (this.props.reports.headers.customer && this.props.reports.headers.customer.display) &&
                                    <td>
                                    {
                                        record?.Customer?.FirstName || record?.Customer?.LastName ? 
                                        this.props.global.capitalizeFirstLetterOfEachWord(`${record.Customer.FirstName} ${record.Customer.LastName}`) : 
                                        '-'
                                    }
                                    </td>
                                }
                                { (this.props.reports.headers.customerNumber && this.props.reports.headers.customerNumber.display) &&
                                    <td>{record.Customer && record.Customer.CustomerNumber ? record.Customer.CustomerNumber :"-" }</td>
                                }
                                { (this.props.reports.headers.payor && this.props.reports.headers.payor.display) &&
                                    <td>{record && record.PaymentData && record.PaymentData.HolderName ? this.props.global.capitalizeFirstLetterOfEachWord(record.PaymentData.HolderName):'-'}</td>
                                }
                                { (this.props.reports.headers.billingAddress && this.props.reports.headers.billingAddress.display) &&
                                    <td>{record.Customer && record.Customer.BillingAddress1 ? record.Customer.BillingAddress1 +" "+ record.Customer.BillingAddress2+" "+ record.Customer.BillingCity+" "+ record.Customer.BillingState+" "+ record.Customer.BillingZip+" "+ record.Customer.BillingCountry: '-'} </td>
                                }                                
                                { (this.props.reports.headers.shippingAddress && this.props.reports.headers.shippingAddress.display) &&
                                    <td>{record.Customer && record.Customer.ShippingAddress1 ? record.Customer.ShippingAddress1 +" "+ record.Customer.ShippingAddress2+" "+ record.Customer.ShippingCity+" "+ record.Customer.ShippingState+" "+ record.Customer.ShippingZip+" "+ record.Customer.ShippingCountry: '-'} </td>
                                }
                                { (this.props.reports.headers.invoiceNumber && this.props.reports.headers.invoiceNumber.display) &&
                                    <td>{record.invoiceData && record.invoiceData.invoiceNumber ? record.invoiceData.invoiceNumber: '-'}</td>
                                }                                
                                { (this.props.reports.headers.chargebackDate && this.props.reports.headers.chargebackDate.display) &&
                                    <td>{ record && record.ChargebackDate ? this.props.global.stringDateFormatV3(record.ChargebackDate) : "-"}</td>
                                }                                
                                { (this.props.reports.headers.createdAt && this.props.reports.headers.createdAt.display) &&
                                    <td>{this.props.global.stringDateFormatV3(record.CreatedAt)}</td>
                                }                                
                                { (this.props.reports.headers.replyDate && this.props.reports.headers.replyDate.display) &&
                                    <td>{ record.Method && record.Method.toLowerCase() === "ach" ? "N/A" : this.props.global.stringDateFormatV3(this.props.global.addDays(record.CreatedAt, 10))}</td>
                                }                                
                                { (this.props.reports.headers.caseNumber && this.props.reports.headers.caseNumber.display) &&
                                    <td>{ record.Method && record.Method.toLowerCase() === "ach" ? "N/A" : record.CaseNumber ? record.CaseNumber : '-' }</td>
                                }                                
                                { (this.props.reports.headers.reasonCode && this.props.reports.headers.reasonCode.display) &&
                                    <td>{record.ReasonCode ? record.ReasonCode : '-'}</td>
                                }                                
                                { (this.props.reports.headers.reason && this.props.reports.headers.reason.display) &&
                                    <td>{record.Reason ? record.Reason : '-'}</td>
                                }                                
                                { (this.props.reports.headers.referenceNumber && this.props.reports.headers.referenceNumber.display) &&
                                <>
                                    {record && record.ReferenceNumber ? 
                                        <>
                                        <td>
                                        <a onClick={(e) => this.showTransactionDetails(record,e)} href='#'>{record.ReferenceNumber}</a>
                                        </td>
                                        </>
                                        :
                                    <td className="text-center">
                                        <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Not available to match the original transaction</Tooltip>}
                                        >
                                        <BiUnlink className="label-info-icon in-input" style={{fontSize: "15px"}}/>
                                        </OverlayTrigger>
                                    </td>
                                    }
                                </>                                }                                
                                { (this.props.reports.headers.lastFour && this.props.reports.headers.lastFour.display) &&
                                    <td>{ this.props.chargeback.getChargebackLast4(record) }</td>
                                }
                                { (this.props.reports.headers.accountType && this.props.reports.headers.accountType.display) &&
                                    <td className='text-center'>
                                    {this.props.global.getPaymethodImgPaymentData(record)}
                                    </td>
                                }
                                { (this.props.reports.headers.netAmount && this.props.reports.headers.netAmount.display) &&
                                    <td className='text-right'>${record.NetAmount ? this.props.global.numberWithCommas(record.NetAmount.toFixed(2)) : "0.00"}</td>
                                }
                                { (this.props.reports.headers.currency && this.props.reports.headers.currency.display) &&
                                    <td className='text-center'>{record && record.PaymentData && record.PaymentData.paymentDetails && record.PaymentData.paymentDetails.currency ? record.PaymentData.paymentDetails.currency : '-'}</td>
                                }                                
                                { (this.props.reports.headers.transactionTime && this.props.reports.headers.transactionTime.display) &&
                                    <td>{this.props.global.stringTimeFormat(record.TransactionTime)}</td>
                                }
                                { (this.props.reports.headers.cycle && this.props.reports.headers.cycle.display) && 
                                    <td>{record.ScheduleReference > 0 ? 'Scheduled' : 'One-Time'}</td>
                                }                            
                                { (this.props.reports.headers.status && this.props.reports.headers.status.display) &&
                                    <td>{this.props.global.getChargebackStatus(record?.Status)}</td>
                                }
                                { (this.props.reports.headers.notes && this.props.reports.headers.notes.display) && 
                                    <td className="text-center">
                                        {record.PaymentData && record.PaymentData.orderDescription ? 
                                            <OverlayTrigger
                                            placement="top"
                                            overlay={<Tooltip>{record.PaymentData.orderDescription}</Tooltip>}
                                            >
                                            <BiNotepad className="label-info-icon in-input"/>
                                            </OverlayTrigger>
                                        : '-'}
                                    </td>
                                }
                                {
                                this.state.customFields.map((field, i) => (
                                    (this.props.reports.headers[field.key] && this.props.reports.headers[field.key].display) && 
                                        <td key={i}>{record.Customer && record.Customer.AdditionalData && record.Customer.AdditionalData[field.key] ? record.Customer.AdditionalData[field.key] : '-'}</td>
                                    ))
                                }
                                <td className="text-center sticky-row">
                                    <DropdownButton
                                        menuAlign="right"
                                        title={<BiDotsVerticalRounded/>}
                                        data-qaid="actionChargebacksMoneyIn"
                                        id="actionsMenuButton"
                                        size="sm"
                                        variant="default"
                                    >
                                        {userPermissions.indexOf("Chargeback") !== -1 && 
                                            <>
                                            <Dropdown.Item data-qaid="viewChargebackOrReturnMoneyInLink" as={Link} to={"/" + this.props.global.getURLEntry() + "/chargeback/"+ record.Id}>{(record.Method && record.Method.toLowerCase() !== 'ach') ? "View Chargeback" : "View ACH Return"}</Dropdown.Item>
                                                {(record.Method && record.Method.toLowerCase() !== 'ach') &&
                                                    <Dropdown.Item as={Link} to={"/" + this.props.global.getURLEntry() + "/chargeback/"+ record.Id + '/respond'} data-qaid="respondChargebackMoneyInLink">Respond to Chargeback</Dropdown.Item>
                                                }
                                            </>
                                        }
                                    </DropdownButton>
                                </td>
                            </tr>
                        ))}                        
                        </tbody>
                        </table>
                        {this.props.reports.records.length < 1 &&
                            <RecordsNotFound message="No chargebacks yet" description={<span>When a customer disputes a transaction, you will find them here<br/> You can track and respond to disputes in one place.</span>}/>
                        }                        
                    </div>                    
                    <ReportPaginator report="chargebacks"/>
                </div>
                <ToastContainer transition={Bounce} />
            </div>
            </Layout>
        )
    }
}

export { ChargebacksReport };
