import React from "react";
import { BiLogoApple, BiLogoGoogle } from "react-icons/bi";
import { inject, observer } from "mobx-react";

@inject("store", "googlepay")
@observer
class GooglePayButton extends React.Component {
  constructor(props) {
    super(props);
    const { buttonStyle, buttonType } =
      this.props.store.paymentPage.paymentMethods.settings.googlePay;
    this.buttonStyle = buttonStyle;
    this.buttonType = buttonType;
  }
  render() {
    const isWhiteOutline = this.buttonStyle === "white-outline";
    const buttonClasses = `btn ${
      this.buttonStyle === "black" ? "btn-dark" : ""
    } btn-block d-flex align-items-center justify-content-center w-100 ${
      isWhiteOutline ? "border border-dark" : ""
    }`;

    return (
      <button
        className={buttonClasses}
        style={{
          borderRadius: "6px",
          height: "50px",
        }}
        onClick={() => {
          this.props.onClick();
        }}
      >
        <span
          className="fs-5"
          style={{ fontWeight: "600", marginRight: "3px" }}
        >
          {
            this.props.googlepay.getGooglePayButtonType(this.buttonType)
              ?.displayText
          }
        </span>
        <span className="fs-5 d-flex align-items-center my-auto">
          <BiLogoGoogle />
        </span>
        <span  className="fs-5" style={{ fontWeight: "600", marginLeft: "2px", textTransform: 'capitalize' }}>
          Pay
        </span>
      </button>
    );
  }
}

export default GooglePayButton
