import React from "react";
import { inject, observer } from "mobx-react";
import { Accordion, Card } from "react-bootstrap";
import { PaymentMethodCCForm } from "../../components/PaymentMethodCCForm";
import { PaymentMethodECheck } from "../../components/PaymentMethodECheck";
import { PaymentMethodsAccepted } from "../../components/PaymentMethodsAccepted";
import { TopFixedMessage } from "../../components/TopFixedMessage";
import { IMaskInput } from "react-imask";
import { toJS } from "mobx";
import { PaymentPageLogo } from "../../components/PaymentPageLogo";
import { Helmet } from "react-helmet";
import NumberFormat from "react-number-format";
import { PayabliStorageManager } from "../../api/localStorageManager";
import { ApplePayButton } from "../../components/ApplePayButton";
import GooglePayButton from "../../components/GooglePayButton";

@inject("store", "global", "vTerminal")
@observer
class Preview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputQtyError: false,
      methodSelected: "1",
    };
    this.handleCategoryTextChangeMask =
      this.handleCategoryTextChangeMask.bind(this);
    this.renderSortBlocks = this.renderSortBlocks.bind(this);
    this.goToPreviewReceipt = this.goToPreviewReceipt.bind(this);
    this.prevent = this.prevent.bind(this);
    this.focus = this.focus.bind(this);
    this.handleCategoryQtySubstract =
      this.handleCategoryQtySubstract.bind(this);
    this.handleCategoryQtyAdd = this.handleCategoryQtyAdd.bind(this);
    this.handleCategoryQtyInput = this.handleCategoryQtyInput.bind(this);
    this.handleCategoryQtyInputWithoutZero =
      this.handleCategoryQtyInputWithoutZero.bind(this);
  }

  prevent(e) {
    e.preventDefault();
  }

  goToPreviewReceipt() {
    this.props.history.push(
      "/" +
        PayabliStorageManager.getEntryName() +
        "/previewreceipt/" +
        this.props.store.getPaymentPageSubdomain
    );
  }

  focus(e) {
    e.target.focus();
    e.target.setSelectionRange(0, 1000000000);
  }

  renderFields() {
    var loopData = [];
    var payerFieldsSort = this.sortFields(
      Object.entries(toJS(this.props.store.payerFields))
    );

    payerFieldsSort.forEach(function (item, index) {
      loopData.push(
        item[1].display && (
          <div key={"keyPayer" + item[0]} className={"col-sm-" + item[1].width}>
            <div className="form-floating mb-3">
              <input
                name={"payorFieldInput" + item[0]}
                type="text"
                className="form-control"
                placeholder={item[1].label}
              />
              <label htmlFor={"payorFieldInput" + item[0]}>
                {item[1].label}
              </label>
            </div>
          </div>
        )
      );
    });
    return loopData;
  }

  handleCategoryTextChangeMask(field, key, value) {
    this.props.store.handleCategoryTextChangeMask(field, key, value);
  }

  handleCategoryQtySubstract(event) {
    this.props.store.handleCategoryQtySubstract(event);
  }

  handleCategoryQtyAdd(event) {
    this.props.store.handleCategoryQtyAdd(event);
  }

  handleCategoryQtyInput(event) {
    if (!this.state.inputQtyError) {
      this.props.store.handleCategoryQtyInput(event);
    }
  }

  handleCategoryQtyInputWithoutZero(e) {
    if (e.target.value.length === 0) {
      this.setState({ inputQtyError: true });
    } else {
      this.setState({ inputQtyError: false });
    }
  }

  renderAmountCategories() {
    var loopData = [];
    var categoriesConfigSort = this.sortFields(
      Object.entries(toJS(this.props.store.amountCategories))
    );
    var categoriesCount = categoriesConfigSort.length;
    var reactObject = this;

    categoriesConfigSort.forEach(function (item, index) {
      if (categoriesCount > 1) {
        loopData.push(
          <li
            key={"keyAmountCategory" + item[0]}
            className="ui-state-default dragable-panel"
            id={"blockcategory" + item[0]}
          >
            <div className="form-floating form-floating-money">
              <NumberFormat
                onFocus={(e) => reactObject.focus(e)}
                thousandsGroupStyle="thousand"
                decimalSeparator="."
                displayType="input"
                type="text"
                thousandSeparator={true}
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
                value={item[1].value}
                placeholder={item[1].label}
                className="form-control input-money"
                onValueChange={(values) =>
                  reactObject.handleCategoryTextChangeMask(
                    item[0],
                    "value",
                    values.value
                  )
                }
                readOnly={
                  item[1].type === "fixed" || item[1].type === "quantity"
                    ? true
                    : false
                }
              />
              <label>{item[1].label}</label>
            </div>
            {item[1].optionalPay && (
              <div className="icheck-primary optional-pay">
                <input
                  type="checkbox"
                  id={"amountCategoryOptionalPay" + item[0]}
                />
                <label htmlFor={"amountCategoryOptionalPay" + item[0]}></label>
              </div>
            )}

            {item[1].type === "quantity" && (
              <div
                className={
                  item[1].optionalPay
                    ? "input-group input-group-qty input-group-qty-mr"
                    : "input-group input-group-qty"
                }
                style={{ width: item[1].quantity.toString().length + 3 + "em" }}
              >
                <button
                  id={"amountCategoryQtySubstract" + item[0]}
                  className="btn btn-outline-light btn-sm"
                  type="button"
                  onClick={(e) => reactObject.handleCategoryQtySubstract(e)}
                >
                  -
                </button>
                <IMaskInput
                  id={"amountCategoryQtyInput" + item[0]}
                  mask={"00000000"}
                  className={"form-control"}
                  value={String(item[1].quantity)}
                  onChange={(e) => reactObject.handleCategoryQtyInput(e)}
                  onBlur={(e) =>
                    reactObject.handleCategoryQtyInputWithoutZero(e)
                  }
                />
                <button
                  id={"amountCategoryQtyAdd" + item[0]}
                  className="btn btn-outline-light btn-sm"
                  type="button"
                  onClick={(e) => reactObject.handleCategoryQtyAdd(e)}
                >
                  +
                </button>
              </div>
            )}

            {item[1].description && item[1].showDescription ? (
              <p className="small-grey-m0 mt-1">{item[1].description}</p>
            ) : (
              ""
            )}
          </li>
        );
      } else {
        loopData.push(
          <li
            key={"keyAmountCategory" + item[0]}
            className="ui-state-default dragable-panel"
            id={"blockcategory" + item[0]}
          >
            <NumberFormat
              onFocus={(e) => reactObject.focus(e)}
              thousandsGroupStyle="thousand"
              prefix="$"
              decimalSeparator="."
              displayType="input"
              type="text"
              thousandSeparator={true}
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              value={item[1].value}
              placeholder={"$"}
              className="big-amount"
              onValueChange={(values) =>
                reactObject.handleCategoryTextChangeMask(
                  item[0],
                  "value",
                  values.value
                )
              }
              readOnly={
                item[1].type === "fixed" || item[1].type === "quantity"
                  ? true
                  : false
              }
            />
            {item[1].type === "quantity" && (
              <div
                className={
                  item[1].optionalPay
                    ? "input-group input-group-qty-big input-group-qty-mr"
                    : "input-group input-group-qty-big"
                }
                style={{ width: item[1].quantity.toString().length + 3 + "em" }}
              >
                <button
                  id={"amountCategoryQtySubstract" + item[0]}
                  className="btn btn-outline-light btn-sm"
                  type="button"
                  onClick={(e) => reactObject.handleCategoryQtySubstract(e)}
                >
                  -
                </button>
                <IMaskInput
                  id={"amountCategoryQtyInput" + item[0]}
                  mask={"00000000"}
                  className={"form-control"}
                  value={String(item[1].quantity)}
                  onChange={(e) => reactObject.handleCategoryQtyInput(e)}
                  onBlur={(e) =>
                    reactObject.handleCategoryQtyInputWithoutZero(e)
                  }
                />
                <button
                  id={"amountCategoryQtyAdd" + item[0]}
                  className="btn btn-outline-light btn-sm"
                  type="button"
                  onClick={(e) => reactObject.handleCategoryQtyAdd(e)}
                >
                  +
                </button>
              </div>
            )}
          </li>
        );
      }
    });

    return loopData;
  }

  sortFields(inputArr) {
    let len = inputArr.length;
    for (let i = 0; i < len; i++) {
      for (let j = 0; j < len - 1; j++) {
        if (inputArr[j][1].order > inputArr[j + 1][1].order) {
          let tmp = inputArr[j];
          inputArr[j] = inputArr[j + 1];
          inputArr[j + 1] = tmp;
        }
      }
    }
    return inputArr;
  }

  sortBlocks(inputArr) {
    let len = inputArr.length;
    for (let i = 0; i < len; i++) {
      for (let j = 0; j < len - 1; j++) {
        if (inputArr[j].order > inputArr[j + 1].order) {
          let tmp = inputArr[j];
          inputArr[j] = inputArr[j + 1];
          inputArr[j + 1] = tmp;
        }
      }
    }
    return inputArr;
  }

  componentDidMount() {
    this.props.global.protect(this.props.history);

    this.props.global.setLoading(true);
    this.props.store.setCurrentPage("payment-page-preview");
    var subdomain = this.props.match.params.subdomain;
    if (subdomain) {
      this.props.store
        .getPaymentPageFromApi(subdomain)
        .then((result) => {
          this.props.global.setLoading(false);
        })
        .catch((error) => {
          this.props.history.push("/404");
        });
    } else {
      this.props.history.push("/404");
    }

    this.props.vTerminal
      .getPaymentPageFromApi()
      .then((res) => {
        this.props.global.setLoading(false);
      })
      .catch((error) => {
        this.props.global.setLoading(false);
      });
  }

  renderSortBlocks() {
    let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;
    const btnTheme = {
      backgroundColor: this.props.store.paymentPageSettings.color,
    };

    const linkTheme = {
      color: this.props.store.paymentPageSettings.color,
    };

    const bgTheme = {
      backgroundColor: this.props.store.paymentPageSettings.color,
    };

    var blocksUI = [
      {
        elements: (
          <>
            {this.props.store.paymentPage.logo.enabled && (
              <li
                className="ui-state-default dragable-panel"
                id="blocklogo"
                style={{ paddingBottom: 0 }}
              >
                <div className="text-center">
                  <PaymentPageLogo />
                </div>
              </li>
            )}
          </>
        ),
        order: this.props.store.paymentPage.logo.order
          ? this.props.store.paymentPage.logo.order
          : 0,
      },

      {
        elements: (
          <>
            {" "}
            {this.props.store.paymentPage.page.enabled && (
              <li
                className="ui-state-default dragable-panel"
                id="blockpage"
                style={{ paddingTop: 0 }}
              >
                <div className="row">
                  <div className="col-md-8 offset-md-2 progress-steps mb-3">
                    <div className="row">
                      <div style={bgTheme} className="col active">
                        &nbsp;
                      </div>
                      <div className="col">&nbsp;</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <h3 className="card-title text-center">
                      {this.props.store.paymentPage.page.header
                        ? this.props.store.paymentPage.page.header
                        : "[Page Header]"}
                    </h3>
                    <p
                      className="small small-grey text-center m0"
                      dangerouslySetInnerHTML={{
                        __html: this.props.store.paymentPage.page.description
                          ? this.props.store.paymentPage.page.description
                          : "[Page Description]",
                      }}
                    ></p>
                  </div>
                </div>
              </li>
            )}
          </>
        ),
        order: this.props.store.paymentPage.page.order
          ? this.props.store.paymentPage.page.order
          : 0,
      },

      {
        elements: (
          <li className="ui-state-default dragable-panel" id="blockamount">
            <div className="payment-categories">
              <ul className="sorted" ref={this.sortableCategories}>
                {this.renderAmountCategories()}
              </ul>
            </div>
            {this.props.store.isAutopay && (
              <p className="text-center">
                <a style={linkTheme} href="/" onClick={(e) => this.prevent(e)}>
                  Click here
                </a>{" "}
                to make One-Time Payment
              </p>
            )}
          </li>
        ),
        order: this.props.store.paymentPage.amount.order
          ? this.props.store.paymentPage.amount.order
          : 0,
      },

      {
        elements: (
          <>
            {this.props.store.paymentPage.autopay.enabled && (
              <li className="ui-state-default dragable-panel" id="blockautopay">
                <h6 className="sub-header mb-3">
                  {this.props.store.paymentPage.autopay.header
                    ? this.props.store.paymentPage.autopay.header
                    : "[AutoPay Header]"}
                </h6>
                <div className="row">
                  <div className="col-sm">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control input-calendar"
                        placeholder="Start Date"
                      />
                      <label htmlFor="floatingInput">Start Date</label>
                    </div>
                  </div>
                  <div className="col-sm">
                    <div className="form-floating mb-3">
                      <select
                        className="form-select form-control"
                        id="floatingSelectGrid"
                        aria-label="Frequency"
                      >
                        <option defaultValue="select"> Select... </option>
                        {this.props.store.paymentPage.autopay.frequency
                          .onetime && <option>One Time</option>}
                        {this.props.store.paymentPage.autopay.frequency
                          .weekly && <option>Weekly</option>}
                        {this.props.store.paymentPage.autopay.frequency
                          .every2Weeks && <option>Every 2 weeks</option>}
                        {this.props.store.paymentPage.autopay.frequency
                          .monthly && <option>Monthly</option>}
                        {this.props.store.paymentPage.autopay.frequency
                          .every3Months && <option>Every 3 months</option>}
                        {this.props.store.paymentPage.autopay.frequency
                          .every6Months && <option>Every 6 months</option>}
                        {this.props.store.paymentPage.autopay.frequency
                          .annually && <option>Annually</option>}
                      </select>
                      <label htmlFor="floatingSelectGrid">Frequency</label>
                    </div>
                  </div>
                  <div className="col-sm">
                    {this.props.store.paymentPage.autopay.finish
                      .untilCancelled && (
                      <div className="form-floating mb-3">
                        <select
                          className="form-select form-control"
                          id="floatingSelectGrid"
                          aria-label="End Date"
                        >
                          <option defaultValue="select"> Select... </option>
                          <option value="1">Until Cancelled</option>
                        </select>
                        <label htmlFor="floatingSelectGrid">End Date</label>
                      </div>
                    )}

                    {this.props.store.paymentPage.autopay.finish.calendar && (
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control input-calendar"
                          placeholder="End Date"
                        />
                        <label htmlFor="floatingInput">End Date</label>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            )}
          </>
        ),
        order: this.props.store.paymentPage.autopay.order
          ? this.props.store.paymentPage.autopay.order
          : 0,
      },

      {
        elements: (
          <>
            {this.props.store.paymentPage.payor.enabled && (
              <li className="ui-state-default dragable-panel" id="blockpayor">
                <h6 className="sub-header mb-3">
                  {this.props.store.paymentPage.payor.header
                    ? this.props.store.paymentPage.payor.header
                    : "[Customer Information Header]"}
                </h6>
                <div className="row">{this.renderFields()}</div>
              </li>
            )}{" "}
          </>
        ),
        order: this.props.store.paymentPage.payor.order,
      },

      {
        elements: (
          <li
            className="ui-state-default dragable-panel"
            id="blockpaymentMethods"
          >
            <h6 className="sub-header mb-3">
              {this.props.store.paymentPage.paymentMethods.header
                ? this.props.store.paymentPage.paymentMethods.header
                : "[Payment Methods Header]"}
            </h6>
            {!credentialsPermissions.ach.onetime &&
              !credentialsPermissions.ach.recurring &&
              !credentialsPermissions.card.onetime &&
              !credentialsPermissions.card.recurring &&
              !credentialsPermissions.wallet.onetime && (
                <div style={{ margin: "50px 0 50px 0" }}>
                  <div className="alert alert-warning small">
                    There are no services activated for this Paypoint. Contact
                    your administrator to enable it.
                  </div>
                </div>
              )}
            <Accordion defaultActiveKey="1">
              {(credentialsPermissions.ach.onetime ||
                credentialsPermissions.ach.recurring) && (
                <Card className="card card-in mb-3 card-hover">
                  <Accordion.Toggle
                    as={Card.Header}
                    className="card-body"
                    eventKey="1"
                    onClick={() => this.setState({ methodSelected: "1" })}
                  >
                    <span className="badge bg-dark card-info-badge">
                      Most popular method
                    </span>
                    <div className="row">
                      <div className="col-2 text-center pt-1">
                        {this.props.global.getGlobalImg("ach", "30px")}
                      </div>
                      <div className="col-10">
                        Bank account / E-Check
                        <p className="small small-grey-m0">
                          Pay from your Bank Account
                        </p>
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <PaymentMethodECheck history={this.props.history} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )}
              {(credentialsPermissions.card.onetime ||
                credentialsPermissions.card.recurring) && (
                <Card className="card card-in mb-3 card-hover">
                  <Accordion.Toggle
                    as={Card.Header}
                    className="card-body"
                    eventKey="0"
                    onClick={() => this.setState({ methodSelected: "0" })}
                  >
                    <div className="row">
                      <div className="col-2 text-center">
                        {this.props.global.getGlobalImg("card", "30px")}
                      </div>
                      <div className="col-10">
                        Credit or Debit Card
                        <p className="small small-grey-m0">
                          Use your Credit or Debit Card
                        </p>
                        <div
                          className="card-brands accordion-right-corner"
                          style={{ position: "absolute" }}
                        >
                          <PaymentMethodsAccepted />
                        </div>
                      </div>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <PaymentMethodCCForm history={this.props.history} />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )}
              {credentialsPermissions.wallet.onetime && this.props.store.paymentPage.paymentMethods.methods.applePay && (
                <Card className="card card-in mb-3 card-hover">
                  <Accordion.Toggle
                    as={Card.Header}
                    className="card-body"
                    eventKey="2"
                    onClick={() => this.setState({ methodSelected: "2" })}
                  >
                    <div className="row">
                      <div className="col-2 text-center my-auto">
                        {this.props.global.getGlobalImg("applepay", "30px")}
                      </div>
                      <div className="col-10">
                        Apple Pay
                        <p className="small small-grey-m0">
                          Apple Pay is an easy and secure way to pay
                        </p>
                        <div
                          className="wallets-accepted accordion-right-corner"
                          style={{ position: "absolute" }}
                        ></div>
                      </div>
                    </div>
                  </Accordion.Toggle>
                </Card>
              )}
              {credentialsPermissions.wallet.onetime && this.props.store.paymentPage.paymentMethods.methods.googlePay && (
                <Card className="card card-in mb-3 card-hover">
                  <Accordion.Toggle
                    as={Card.Header}
                    className="card-body"
                    eventKey="2"
                    onClick={() => this.setState({ methodSelected: "3" })}
                  >
                    <div className="row">
                      <div className="col-2 text-center my-auto">
                        {this.props.global.getGlobalImg("googlepay", "30px")}
                      </div>
                      <div className="col-10">
                        Google Pay
                        <p className="small small-grey-m0">
                          Google Pay is an easy and secure way to pay
                        </p>
                        <div
                          className="wallets-accepted accordion-right-corner"
                          style={{ position: "absolute" }}
                        ></div>
                      </div>
                    </div>
                  </Accordion.Toggle>
                </Card>
              )}
            </Accordion>
          </li>
        ),
        order: this.props.store.paymentPage.paymentMethods.order
          ? this.props.store.paymentPage.paymentMethods.order
          : 0,
      },

      {
        elements: (
          <>
            {" "}
            {this.props.store.paymentPage.notes.enabled && (
              <li className="ui-state-default dragable-panel" id="blocknotes">
                <h6 className="sub-header mb-3">
                  {this.props.store.paymentPage.notes.header
                    ? this.props.store.paymentPage.notes.header
                    : "[Notes Header]"}
                </h6>
                <div className="mb-3">
                  <textarea
                    style={{ height: "75px" }}
                    className="form-control"
                    id="notesTextarea"
                    placeholder={
                      this.props.store.paymentPage.notes.placeholder
                        ? this.props.store.paymentPage.notes.placeholder
                        : "[Notes Placeholder]"
                    }
                  ></textarea>
                </div>
              </li>
            )}{" "}
          </>
        ),
        order: this.props.store.paymentPage.notes.order
          ? this.props.store.paymentPage.notes.order
          : 0,
      },

      {
        elements: (
          <>
            {this.props.store.paymentPage.review.enabled && (
              <li className="ui-state-default dragable-panel" id="blockreview">
                <div className="review-total">
                  <h6 className="sub-header-line mb-3">
                    {this.props.store.paymentPage.review.header
                      ? this.props.store.paymentPage.review.header
                      : "[Review & Send Payment Header]"}
                  </h6>
                  <div className="row">
                    <div className="col">Net Amount:</div>
                    <div className="col">$0.00</div>
                  </div>
                  <div className="row">
                    <div className="col">Fee:</div>
                    <div className="col">$0.00</div>
                  </div>

                  <div className="row total-amount">
                    <div className="col">Total Amount:</div>
                    <div className="col">$0.00</div>
                  </div>
                </div>
              </li>
            )}
          </>
        ),
        order: this.props.store.paymentPage.review.order
          ? this.props.store.paymentPage.review.order
          : 0,
      },

      {
        elements: (
          <>
            {this.props.store.paymentPage.messageBeforePaying.enabled && (
              <li
                className="ui-state-default dragable-panel"
                id="blockmessageBeforePaying"
              >
                <p
                  className="small small-grey"
                  style={{ margin: 0 }}
                  dangerouslySetInnerHTML={{
                    __html: this.props.store.paymentPage.messageBeforePaying
                      .label
                      ? this.props.store.paymentPage.messageBeforePaying.label
                      : "[Message Before Paying]",
                  }}
                ></p>
              </li>
            )}
          </>
        ),
        order: this.props.store.paymentPage.messageBeforePaying.order
          ? this.props.store.paymentPage.messageBeforePaying.order
          : 0,
      },

      {
        elements: (
          <li
            className="ui-state-default dragable-panel"
            id="blockpaymentButton"
          >
            <div className="btn-group full-w">
              {(() => {
                if(this.state.methodSelected === '2') {
                  return <ApplePayButton onClick={this.goToPreviewReceipt} />
                }
                if(this.state.methodSelected === '3') {
                  return <GooglePayButton onClick={this.goToPreviewReceipt} />
                }
                return (
                  <button
                  style={btnTheme}
                  type="button"
                  onClick={this.goToPreviewReceipt}
                  className="btn btn-success btn-lg"
                >
                  {this.props.store.paymentPage.paymentButton.label} $0.00
                </button>
                )
              })()}
            </div>
          </li>
        ),
        order: this.props.store.paymentPage.paymentButton.order
          ? this.props.store.paymentPage.paymentButton.order
          : 0,
      },

      {
        elements: (
          <>
            {this.props.store.paymentPage.contactUs.enabled && (
              <li
                className="ui-state-default dragable-panel"
                id="blockcontactUs"
              >
                <p className="small-grey-m0 text-center ">
                  <b>
                    {this.props.store.paymentPage.contactUs.header
                      ? this.props.store.paymentPage.contactUs.header
                      : "[Contact Us Header]"}
                  </b>
                  <br />
                  {this.props.store.paymentPage.contactUs.emailLabel
                    ? this.props.store.paymentPage.contactUs.emailLabel
                    : "[email]"}{" "}
                  |{" "}
                  {this.props.store.paymentPage.contactUs.phoneLabel
                    ? this.props.store.paymentPage.contactUs.phoneLabel
                    : "[Phone Number]"}
                </p>
              </li>
            )}
          </>
        ),
        order: this.props.store.paymentPage.contactUs.order
          ? this.props.store.paymentPage.contactUs.order
          : 0,
      },
    ];

    return this.sortBlocks(blocksUI);
  }

  render() {
    const bgTheme = {
      backgroundColor: this.props.store.paymentPageSettings.color,
      height: "50%",
    };
    const linkTheme = {
      color: this.props.store.paymentPageSettings.color,
    };

    return (
      <div>
        {this.props.global.isLoading && (
          <div
            id="main-loading-layer"
            className="d-flex justify-content-center align-items-center"
          >
            <div className="spinner-border" role="status"></div>
          </div>
        )}

        <div className="bg-decorator" style={bgTheme}>
          &nbsp;
        </div>
        <div className="mt-body3">
          <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
            <TopFixedMessage message="This page is a Page Preview" />
            <div className="card" style={{ width: "50em" }}>
              <div className="card-body  main-cont">
                <ul className="sorted" ref={this.sortablePayer}>
                  {this.renderSortBlocks().map((block, i) => (
                    <div key={i}>{block.elements}</div>
                  ))}
                </ul>
              </div>

              {this.props.store.paymentPage.contactUs.paymentIcons && (
                <div className="card-brands text-center bottom-grey mt-3">
                  <PaymentMethodsAccepted />
                </div>
              )}
            </div>
            <p className="small-small mt-5">
              Powered by{" "}
              <a
                rel="noreferrer"
                style={linkTheme}
                target="_blank"
                href="https://www.payabli.com/"
                className="small no-underline"
              >
                Payabli
              </a>
            </p>
          </div>
        </div>
        {this.props.store &&
          this.props.store.paymentPageSettings.customCssUrl && (
            <Helmet>
              <link
                rel="stylesheet"
                href={this.props.store.paymentPageSettings.customCssUrl}
              />
            </Helmet>
          )}
      </div>
    );
  }
}

export { Preview };
