import './assets/css/bootstrap.min.css'
import './assets/css/icheck-bootstrap.min.css'
import './assets/css/custom.css'
import React, { Component } from 'react'
import { Builder } from './views/PaymentPage/Builder'
import { Settings } from './views/PaymentPage/Settings'
import { Receipt } from './views/PaymentPage/Receipt'
import { PaymentPages } from './views/PaymentPage/PaymentPages'
import { Preview } from './views/PaymentPage/Preview'
import { PreviewReceipt } from './views/PaymentPage/PreviewReceipt'
import { TransactionsReport } from './views/Reports/TransactionsReport'
import { BatchesReport } from './views/Reports/BatchesReport'
import { AutopayReport } from './views/Reports/AutopayReport'
import { CustomerReport } from './views/Reports/CustomerReport'
import { DevicesReport } from './views/Reports/DevicesReport'
import { CatalogReport } from './views/Reports/CatalogReport'
import { SettlementReport } from './views/Reports/SettlementReport'
import { BatchesTransactionsReport } from './views/Reports/BatchesTransactionsReport'
import { ChargebacksReport } from './views/Reports/ChargebacksReport'
import { ViewChargeback } from './views/Chargeback/ViewChargeback'
import { UserReport } from './views/Reports/UserReport'
import { ViewVirtualTerminal } from './views/VirtualTerminal/ViewVirtualTerminal'
import { Dashboard } from './views/Common/Dashboard'
import { Profile } from './views/User/Profile'
import { Developers } from './views/Common/Developers'
import { ApiKeys } from './views/Developers/ApiKeys'
import { ComponentCreator } from './views/Developers/ComponentCreator'
import { Reports } from './views/Developers/Reports'
import { SftpAccounts } from './views/Developers/SftpAccounts';
import { CustomFields } from './views/Common/CustomFields'
import { Notifications } from './views/Common/Notifications'
import { PaypointDetails } from './views/Common/PaypointDetails'
import { Login } from './views/Auth/Login'
import { Sso } from './views/Auth/Sso'
import { ForgotPassword } from './views/Auth/ForgotPassword'
import { ResetPassword } from './views/Auth/ResetPassword'
import { Root } from './views/Common/Root'
import { PageNotFound } from './views/Common/PageNotFound'
import { Logout } from './views/Auth/Logout'
import { ViewCustomer } from './views/Customer/ViewCustomer'
import { PayMethods } from './views/Customer/PayMethods'
import { Provider } from 'mobx-react'
import PaymentPageStore from './store/PaymentPageStore'
import ApplePayStore from './store/ApplePayStore'
import ReportsStore from './store/ReportsStore'
import GlobalStore from './store/GlobalStore'
import CustomerStore from './store/CustomerStore'
import ManagementStore from './store/ManagementStore'
import VirtualTerminalStore from './store/VirtualTerminalStore'
import UserStore from './store/UserStore'
import EntryStore from './store/EntryStore'
import ChargebackStore from './store/ChargebackStore'
import DeviceStore from './store/DeviceStore'
import InvoiceStore from './store/InvoiceStore'
import VendorStore from './store/VendorStore'
import BillStore from './store/BillStore'
import DevelopersStore from './store/DevelopersStore'
import FilterStore from './store/FilterStore'
import CustomTableStore from './store/CustomTableStore'
import { InvoiceReport } from './views/Invoice/report/InvoiceReport'
import InvoiceBuilder from './views/Invoice/builder/InvoiceBuilder'
import { InvoiceSettings } from './views/Invoice/settings/InvoiceSettings';
import { PayabliStorageManager } from './api/localStorageManager'
import PaymentLink from './views/PaymentLink/PaymentLink'
import { BillsReport } from './views/Reports/BillsReport'
import { VendorsReport } from './views/Reports/VendorsReport'
import { NewOpenResource } from './views/Common/NewOpenResource'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { AccessDenied } from './views/Common/AccessDenied'
import './api/sessionExpiredInterceptor'
import { ViewVendor } from './views/Vendor/ViewVendor'
import { TransactionsReportMoneyOut } from './views/Reports/TransactionsReportMoneyOut'
import BillBuilder from './views/Bills/builder/BillBuilder'
import { ApprovalsReport } from './views/Reports/ApprovalsReport'
import {BatchesReportMoneyOut} from './views/Reports/BatchesReportMoneyOut'
import { NewUser } from './views/User/NewUser'
import { ReportsRiskControl } from './views/RiskControl/ReportsRiskControl'
import googlePayStore from './store/GooglePayStore'

class App extends Component {
  
  render() { 
    if (window.location.href.endsWith('/login') || window.location.pathname === '/') {
      //PayabliStorageManager.clearStorage(PayabliStorageManager.getEntryName());
    }else if(window.location.href.endsWith('/sso')){
      PayabliStorageManager.clearStorage(PayabliStorageManager.getEntryName());
    }
    else {
      let encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
      if(encryptStorage && !encryptStorage.getItem('pUser')){
          if(!window.location.href.includes("/sso") && !window.location.href.endsWith('/login') && !window.location.href.endsWith('/logout') && !window.location.href.includes('/resetpassword/') && encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`)){
            window.location.replace("/" + encryptStorage.getItem(`${PayabliStorageManager.getEntryName()}_pEntry`).pEntry + "/login");
          }
      }
    }

    return (
      <Provider store={PaymentPageStore} reports={ReportsStore} global={GlobalStore} customer={CustomerStore} vTerminal={VirtualTerminalStore} user={UserStore} entry={EntryStore} chargeback={ChargebackStore} device={DeviceStore} invoice={InvoiceStore} vendor={VendorStore} bill={BillStore} filter={FilterStore} ctable={CustomTableStore} developers={DevelopersStore} management={ManagementStore} applepay={ApplePayStore} googlepay={googlePayStore}>
      
      <Router>
        <Switch>
          <Route exact path="/:entryUrl/users/new" component={NewUser}/>
          <Route exact path="/:entryUrl/bills/new" component={BillBuilder}/>
          <Route exact path="/:entryUrl/bills/new/:vendorNumber" component={BillBuilder}/>
          <Route exact path="/:entryUrl/bills/edit/:idBill" component={BillBuilder}/>
          <Route exact path="/:entryUrl/bills/view/:idBill" component={BillBuilder}/>
          <Route exact path="/:entryUrl/report/bills/:id?" component={BillsReport}/>
          <Route exact path="/:entryUrl/report/bills/bill/:billNumber?" component={BillsReport}/>
          <Route exact path="/:entryUrl/report/bills/vendor/:vendorNumber?" component={BillsReport}/>
          <Route exact path="/:entryUrl/report/vendors" component={VendorsReport}/>
          <Route exact path="/:entryUrl/paymentpages" component={PaymentPages}/>
          <Route exact path="/:entryUrl/builder/:subdomain?" component={Builder} />
          <Route exact path="/:entryUrl/settings/:subdomain?" component={Settings} />
          <Route exact path="/:entryUrl/receipt/:subdomain?" component={Receipt} />
          <Route exact path="/:entryUrl/preview/:subdomain?" nav={false} component={Preview}/>
          <Route exact path="/:entryUrl/previewreceipt/:subdomain?" nav={false} component={PreviewReceipt}/>          
          <Route exact path="/:entryUrl/paylink/:paylinkId?" component={PaymentLink}/>
          <Route exact path="/:entryUrl/report/approvals" component={ApprovalsReport}/>
          <Route exact path="/:entryUrl/chargeback/:id/:command?" component={ViewChargeback}/>          
          <Route exact path="/:entryUrl/report/transactions/out/:id?" component={TransactionsReportMoneyOut}/>
          <Route exact path="/:entryUrl/report/transaction/out/:billNumber" component={TransactionsReportMoneyOut}/>
          <Route exact path="/:entryUrl/report/batches/out" component={BatchesReportMoneyOut}/>
          <Route exact path="/:entryUrl/report/batches/transactions/out/:idBatch" component={TransactionsReportMoneyOut}/>
          <Route exact path="/:entryUrl/report/transactions/idtransrefund/:idtransRefund" component={TransactionsReport} />
          <Route exact path="/:entryUrl/report/transactions/:id/:gobackData?" component={TransactionsReport}/>
          <Route exact path="/:entryUrl/report/transactions/id/:paymentTransId" component={TransactionsReport}/>
          <Route exact path="/:entryUrl/report/transactions" component={TransactionsReport}/>
          <Route exact path="/:entryUrl/report/batches" component={BatchesReport}/>
          <Route exact path="/:entryUrl/report/autopays" component={AutopayReport}/>
          <Route exact path="/:entryUrl/report/autopays/:id/:command?" component={AutopayReport}/>
          <Route exact path="/:entryUrl/report/settlements/:batchNumber?" component={SettlementReport}/>
          <Route exact path="/:entryUrl/report/batches/:idBatch" component={BatchesTransactionsReport}/>
          <Route exact path="/:entryUrl/report/chargebacks/:paymentTransId?" component={ChargebacksReport}/>
          <Route exact path="/:entryUrl/report/users" component={UserReport}/>
          <Route exact path="/:entryUrl/report/devices" component={DevicesReport}/>
          <Route exact path="/:entryUrl/report/catalog" component={CatalogReport}/>
          <Route exact path="/:entryUrl/report/customers/:command?" component={CustomerReport}/>
          <Route exact path="/:entryUrl/customer/paymethods/:id/:command?" component={PayMethods}/>
          <Route exact path="/:entryUrl/customer/:id" component={ViewCustomer}/>
          <Route exact path="/:entryUrl/vendor/:id" component={ViewVendor}/>
          <Route exact path="/:entryUrl/report/invoices" component={InvoiceReport}/>
          <Route exact path="/:entryUrl/invoicing" component={InvoiceSettings}/>
          <Route exact path="/:entryUrl/invoices/:id?" component={InvoiceBuilder}/>
          <Route exact path="/:entryUrl/vterminal/:paymentType?" component={ViewVirtualTerminal}/>         
          <Route exact path="/:entryUrl/login" component={Login}/>
          <Route exact path="/:entryUrl/sso" component={Sso} />
          <Route exact path="/:entryUrl/forgotpassword" component={ForgotPassword}/>
          <Route exact path="/:entryUrl/resetpassword/:token" component={ResetPassword}/>
          <Route exact path="/:entryUrl/Logout" component={Logout}/>
          <Route exact path="/:entryUrl/dashboard" component={Dashboard}/>
          <Route exact path="/:entryUrl/profile/:id" component={Profile}/>
          <Route exact path="/:entryUrl/developers" component={Developers}/>
          <Route exact path="/:entryUrl/developers/apitokens" component={ApiKeys}/>
          <Route exact path="/:entryUrl/developers/creator" component={ComponentCreator}/>
          <Route exact path="/:entryUrl/developers/reports" component={Reports}/>
          <Route exact path="/:entryUrl/developers/sftpaccounts" component={SftpAccounts}/>
          <Route exact path="/:entryUrl/riskcontrol/reports" component={ReportsRiskControl} />
          <Route exact path="/:entryUrl/fields" component={CustomFields}/>
          <Route exact path="/:entryUrl/notifications" component={Notifications}/>
          <Route exact path="/:entryUrl/paypoint" component={PaypointDetails}/>
          <Route exact path="/" component={Root}/>
          <Route exact path="/login" component={Root}/>
          <Route exact path="/:entryUrl/accessdenied" component={AccessDenied}/>
          <Route exact path="/:entryUrl/404" component={PageNotFound}/>
          <Route exact path="/newresource" component={NewOpenResource}/>
          <Route exact path="/*" component={PageNotFound}/>
        </Switch>
      </Router>     
      </Provider>
    );
  }
}

export default App;