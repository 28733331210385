import React from 'react';
import { inject, observer } from 'mobx-react';
import { TopBar } from '../../components/TopBar';
import { MainTopBarMenu } from '../../components/MainTopBarMenu';
import "react-datepicker/dist/react-datepicker.css";
import { BiCheck, BiDollar, BiFileBlank, BiHistory, BiInfoCircle, BiMailSend, BiQuestionMark, BiReceipt, BiShieldX, BiTimeFive, BiLinkExternal, BiTimer, BiUndo } from 'react-icons/bi';
import { Layout } from '../../components/Layout'
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ReportsLinks } from '../../components/ReportsLinks';
import { Modal, Tooltip, OverlayTrigger } from 'react-bootstrap';
import {IMaskInput} from 'react-imask';
import { ToastContainer, toast , Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RightPanel } from '../../components/RightPanel';
import { FaBullhorn } from '@react-icons/all-files/fa/FaBullhorn';
import { PayabliStorageManager } from '../../api/localStorageManager';
import QuickViewSplitFundingInformation from '../../components/QuickViewSplitFundingInformation'
import { CgUnavailable } from 'react-icons/cg';

@inject('customer','global', 'vTerminal', 'chargeback', 'reports')
@observer
class ViewChargeback extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            chargebackId: null,
            chargeback: null,
            customerId:0,
            respondModalIsOpen: false,
            previewRespondModalIsOpen: false,
            acceptModalIsOpen: false,

            contactName: '',
            contactEmail: '',
            contactNotes: '',

            contactNamePreview: '',
            contactEmailPreview: '',
            contactNotesPreview: '',
            filesPreview: [],

            formErrors: {},
            fileErrors: [],
            fileSuccess: [],

            files: []
        };
        this.getChargebackFromApi = this.getChargebackFromApi.bind(this);
        this.closeRespondModal = this.closeRespondModal.bind(this);
        this.openRespondModal = this.openRespondModal.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.submitResponse = this.submitResponse.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleTextChangeMask = this.handleTextChangeMask.bind(this);
        this.closePreviewRespondModal = this.closePreviewRespondModal.bind(this);
        this.openPreviewRespondModal = this.openPreviewRespondModal.bind(this);
        this.openRightPanel = this.openRightPanel.bind(this);
        this.closeAcceptModal = this.closeAcceptModal.bind(this);
        this.openAcceptModal = this.openAcceptModal.bind(this);
        this.acceptAction = this.acceptAction.bind(this);
        this.goToPayHistory = this.goToPayHistory.bind(this);
        this.inputFile = React.createRef();
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.getChargebackFromApi(id);
        let command = this.props.match.params.command;

        if(command === "respond"){
            this.openRespondModal();
        }
        this.setState({ chargebackId: id });
    }

    goToPayHistory(){
        let jsonBack = {
            url: "../../../chargeback/"+this.state.chargebackId, 
            label: (this.state.chargeback && this.state.chargeback.Method && this.state.chargeback.Method.toLowerCase() === "ach") ? "Back to ACH Return Details" : "Chargeback Details"
        };
        this.props.history.push('/'+this.props.global.getURLEntry()+'/report/transactions/'+this.state.customerId + "/" + encodeURIComponent(window.btoa(JSON.stringify(jsonBack))));
    }

    acceptAction(){
        this.props.global.setLoading(true);
        this.props.chargeback.acceptLiability(this.state.chargebackId)
        .then(result => {
            this.setState({ acceptModalIsOpen: false });
            this.getChargebackFromApi(this.state.chargebackId);
            this.props.global.setLoading(false);
            toast.success("Chargeback accepted successfully!", {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-success-container'
            });
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    openRightPanel(){
        this.props.global.setRightPanelOpen(true);
    }

    handleTextChange(e){
        this.setState({ [e.target.id]: e.target.value });
    }

    handleTextChangeMask(e, value){
        this.setState({ [e]: value });
    }

    prevent(e){
        e.preventDefault();
    }

   
    getChargebackFromApi(id){
        this.props.global.setLoading(true);
        this.props.chargeback.getChargebackFromApi(id).then(res => {
            let ct = 0;
            if (res && res.Customer) {
                ct = res.Customer.customerId;
            }
            this.setState({ chargeback: res, customerId: ct });
            this.props.global.setLoading(false);
        })
        .catch(error => {
            this.props.global.setLoading(false);
            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
                className: 'toast-error-container',
            });
        });
    }

    submitResponse(){
        let errors = this.validateFields();
        this.setState({ formErrors: errors }, function(){
            if(Object.keys(errors).length === 0 && this.state.fileErrors.length === 0){
                this.props.global.setLoading(true);
                
                let chargeback = {};
                chargeback.id = this.state.chargebackId;
                chargeback.contactName = this.state.contactName;
                chargeback.contactEmail = this.state.contactEmail;
                chargeback.contactNotes = this.state.contactNotes;
                chargeback.attachments = [];
                
                let files = this.state.files;
                for (var i = 0; i < files.length; i++) {
                    chargeback.attachments.push(
                        {
                            ftype: files[i].extension,
                            filename: files[i].name,
                            furl: null,
                            fContent: files[i].base64
                        }
                    )
                }

                this.props.chargeback.newResponse(chargeback)
                .then(result => {
                    //changing status to pending
                    if(this.state.chargeback.Status === 0){
                        this.props.chargeback.setStatus(chargeback.id, 1)
                        .then(result => {
                            this.closeRespondModal();
                            this.getChargebackFromApi(chargeback.id);
                            this.props.global.setLoading(false);
                            toast.success("Chargeback updated successfully!", {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: 'toast-success-container'
                            });
                        })
                        .catch(error => {
                            this.props.global.setLoading(false);
                            let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                            toast.error(errorMessage, {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                className: 'toast-error-container',
                            });
                        });
                    }
                    else{
                        this.closeRespondModal();
                        this.getChargebackFromApi(chargeback.id);
                        this.props.global.setLoading(false);
                        toast.success("Chargeback updated successfully!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                            className: 'toast-success-container'
                        });
                    }

                    this.setState({contactName: '',contactEmail: '',contactNotes: ''})
                })
                .catch(error => {
                    this.props.global.setLoading(false);
                    let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong!";
                    toast.error(errorMessage, {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        className: 'toast-error-container',
                    });
                });
            }
        });
    }

    closeAcceptModal(){
        this.setState({acceptModalIsOpen: false});
    }

    openAcceptModal(){
        this.setState({acceptModalIsOpen: true});
    }

    closeRespondModal(){
        this.setState({respondModalIsOpen: false});
    }
    
    closePreviewRespondModal(){
        this.setState({previewRespondModalIsOpen: false});
    }
    
    openPreviewRespondModal(e, record){
        if(e){
            e.preventDefault();
        }

        this.setState({
            contactNamePreview: record.ContactName,
            contactEmailPreview: record.ContactEmail,
            contactNotesPreview: record.Notes,
            filesPreview: record.Attachments ? record.Attachments.filelist : []
        })
        this.setState({previewRespondModalIsOpen: true});
    }
    
    openRespondModal(e){
        if(e){
            e.preventDefault();
        }
        this.setState({respondModalIsOpen: true});
    }

    validateFields(){
        let validators = this.props.global.validators;
        let errors = {};

        if(validators.isEmpty(this.state.contactName) || validators.isMaxLength(250, this.state.contactName))
        {
            errors['contactName']= true;
        }

        if(validators.isEmpty(this.state.contactNotes))
        {
            errors['contactNotes']= true;
        }

        if(validators.isEmpty(this.state.contactEmail) || validators.isMaxLength(250, this.state.contactEmail) || validators.stringValidator('email',this.state.contactEmail))
        {
            errors['contactEmail']= true;
        }

        return errors;
    }

    selectFile(){
        this.inputFile.current.click();
    }

    handleFileRead(e){
        let files = e.target.files;
        let fileErrors = [];
        let fileSuccess = [];
        let filesToSubmit = [];

        for (var i = 0; i < files.length; i++) {
            let fileExtension = this.props.global.getFileExtension(files[i].type);
            if((
                fileExtension === "jpeg" || 
                fileExtension === "png" || 
                fileExtension === "jpg" || 
                fileExtension === "pdf" || 
                fileExtension === "docx" ||
                fileExtension === "doc"
            ) && files[i].size <= 5000000){
                fileSuccess.push(files[i].name);
                let name = files[i].name;
                this.props.global.readFileBase64(files[i]).then(base64 => {
                    let base64string = base64.split(',');
                    if(base64string[0] &&  base64string[1]){
                        
                        filesToSubmit.push({base64:base64string[1], extension:fileExtension, name: name});
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            }
            else{
                fileErrors.push(files[i].name);
            }
        }
        this.setState({fileErrors: fileErrors, fileSuccess: fileSuccess, files: filesToSubmit});
    }

    getPaymentMethod(record){
        return (
            <>
            {(record && record.PaymentData && record.PaymentData.MaskedAccount && record.PaymentData.MaskedAccount.toLowerCase() === "poi") ?
                <>
                    {this.props.global.getPaymethodImg("device", '45px')}
                </>
                :
                <>
                    {(record && record.Method && record.Method.toLowerCase() === "ach") &&
                    <>
                        {this.props.global.getPaymethodImg('ach', '45px')}
                    </>
                    }
                    {(record && record.Method && record.Method.toLowerCase() === "card") &&
                    <>
                        { record.PaymentData && record.PaymentData.AccountType && record.PaymentData.AccountType.toLowerCase() === "ach" ? 
                            this.props.global.getPaymethodImg("card", '45px')
                        :
                            this.props.global.getPaymethodImg(record.PaymentData.AccountType, '45px')
                        }
                    </>
                    }
                    {((record && record.Method === null) || (record && record.Method === "")) &&
                    <>
                        -
                    </>
                    }
                </>
            }
            </>
        );
    }


    render() {
        let recordDetail = this.state.chargeback ? this.state.chargeback.Transaction: null;
        let customerId = (this.state.chargeback && this.state.chargeback.Customer) ? this.state.chargeback.Customer.customerId : 0;
        let reactObj = this;
        let chargebackObj = this.state.chargeback ? this.state.chargeback : null;
        return (
            <Layout {...this.props}>

                <RightPanel>
                    <h5 className="header mb-3">Transaction Details</h5>
               
                    <div className="small mb-5">
                        <div className="row">
                            <div className="col-4">
                                <label className="header">Date & Time {"("+this.props.global.getTimeZone('v1')+")"}</label>
                            </div>
                            <div className="col-8">
                                {recordDetail ? this.props.global.stringDateFormat(recordDetail.TransactionTime) : '-'}
                                -
                                {recordDetail ? this.props.global.stringTimeFormat(recordDetail.TransactionTime) : '-'}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <label className="header">Type</label>
                            </div>
                            <div className="col-8">
                                {recordDetail ? recordDetail.Operation : '-'}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <label className="header">Status</label>
                            </div>
                            <div className="col-8">
                                {this.props.global.getTransStatus(recordDetail?.TransStatus)}
                            </div>
                            <div className="col-4">
                                <label className="header">Batch Status</label>
                            </div>
                            <div className="col-8">
                                {(recordDetail && recordDetail.SettlementStatus !== null) ?
                                    <>
                                    {
                                        recordDetail.settlementStatus < 0 ?
                                            <a href={"/"+this.props.global.getURLEntry()+"/report/chargebacks/"+recordDetail.PaymentTransId} >{this.props.global.getSettlementTransStatus(recordDetail.SettlementStatus, recordDetail)}</a>
                                            :
                                            this.props.global.getSettlementTransStatus(recordDetail.SettlementStatus, recordDetail)
                                    }
                                    </>
                                    : 
                                    <span className="badge bg-light"><CgUnavailable /> N/A</span>
                                }
                                
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4">
                                <label className="header">Response</label>
                            </div>
                            <div className="col-8">
                                {(recordDetail?.ResponseData?.responsetext) ? this.props.global.capitalizeFirstLetter(recordDetail.ResponseData.responsetext): '-'}
                                {(recordDetail?.ResponseData?.response) ? this.props.global.getIconTransactionResponse(recordDetail.ResponseData.response): ''}
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-4">
                               
                            </div>
                            <div className="col-8">
                                {(recordDetail && recordDetail.ResponseData) ? this.props.global.capitalizeFirstLetter(recordDetail.ResponseData.response_code_text): ''}
                            </div>
                        </div>

                        <div className="row mb-4">
                            {(chargebackObj && chargebackObj.PaymentData && chargebackObj.PaymentData.orderDescription) &&
                                <div className="col-12">
                                <label className="header">Notes:</label>
                                <p>
                                    {chargebackObj.PaymentData.orderDescription}
                                </p>
                                </div>
                            }
                            {(chargebackObj && chargebackObj.PaymentData && chargebackObj.PaymentData.paymentDetails && chargebackObj.PaymentData.paymentDetails.categories) &&
                            <div className="col-12">
                                {chargebackObj.PaymentData.paymentDetails.categories.map((record, i) => (
                                    <div className="row mb-1" key={i}>
                                        <div className="col-4">{record.label}</div>
                                        <div className="col-3">${this.props.global.numberWithCommas((record.amount).toFixed(2))}</div>
                                        <div className="col-2">x {record.qty ? record.qty : 1}</div>
                                        <div className="col-3 text-right">${this.props.global.numberWithCommas(((record.qty ? record.qty : 1) * record.amount).toFixed(2))}</div>
                                    </div>
                                ))
                                }
                            </div>
                            }

                            <div className="col-12">
                                <div className="row mb-1">
                                    <div className="col-4">
                                        <b>Amount</b>
                                    </div>
                                    <div className="col-8 text-right">
                                        {(recordDetail && recordDetail.NetAmount) ? this.props.global.numberWithCommasNegative(recordDetail.NetAmount.toFixed(2)) : '$0.00'}
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-4">
                                        <b>Fee</b>
                                    </div>
                                    <div className="col-8 text-right">
                                        {(recordDetail && recordDetail.FeeAmount) ? this.props.global.numberWithCommasNegative(recordDetail.FeeAmount.toFixed(2)) : '$0.00'}
                                    </div>
                                </div>

                                <div className="row mb-1">
                                    <div className="col-4">
                                        <b>Total</b>
                                    </div>
                                    <div className="col-8 text-right">
                                    <b>{(recordDetail && recordDetail.TotalAmount) ?  this.props.global.numberWithCommasNegative(recordDetail.TotalAmount.toFixed(2)) : '$0.00'}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {Array.isArray(recordDetail?.splitFundingInstructions) && <QuickViewSplitFundingInformation splitFundingInstructions={Array.isArray(recordDetail.splitFundingInstructions) && recordDetail.splitFundingInstructions} />}

                        <h5 className="header mb-3">Payment Information</h5>
                        {(chargebackObj && chargebackObj.PaymentData && chargebackObj.PaymentData.MaskedAccount && chargebackObj.PaymentData.MaskedAccount.toLowerCase() === "poi") ?
                            <div className="card-v2 mb-3" style={{padding: "3em"}}>
                                <div className="row">
                                <div className="col-7">
                                    <label className="grey">Source</label>
                                    <h6 style={{fontWeight: 500}}>
                                        POI Device
                                    </h6>
                                </div>
                                <div className="col-5 text-right">
                                    {this.props.global.getGlobalImg('pointofsale', '30px')}
                                </div>
                                </div>
                            </div>
                        :
                        <div className="card-v2 mb-4" style={{padding: "3em"}}>

                            <div className="right-panel-card mb-4">
                                {this.getPaymentMethod(chargebackObj)}
                            </div>
                       
                            <div className="row mb-2">
                                <div className="col-7">
                                    <label className="grey">{chargebackObj && chargebackObj.Method && chargebackObj.Method.toLowerCase() === "card" ? "Card Number" : "Account Number" } </label>
                                    <h5 style={{fontWeight: 500}}>
                                    •••• •••• {chargebackObj ? this.props.global.maskedCardNumber(chargebackObj.LastFour, 'v5'): '-'}
                                    </h5>
                                </div>
                                {chargebackObj && chargebackObj.Method && chargebackObj.Method.toLowerCase() === "card" ?
                                <div className="col-5 text-center">
                                    <label className="grey">Expires on</label>
                                    <h5 style={{fontWeight: 500}}>
                                        {chargebackObj && chargebackObj.PaymentData && chargebackObj.PaymentData.AccountExp ? this.props.global.expDateFormat(chargebackObj.PaymentData.AccountExp) : '-'}
                                    </h5>
                                </div>
                                :
                                <div className="col-5 text-center">
                                    <label className="grey">Account Type</label>
                                    <h5 style={{fontWeight: 500}}>
                                        {chargebackObj && chargebackObj.PaymentData && chargebackObj.PaymentData.AccountType ? chargebackObj.PaymentData.AccountType  : '-'}
                                    </h5>
                                </div>
                                }
                            </div>

                            <div className="row">
                                <div className="col-12">
                                    <label className="grey">{chargebackObj && chargebackObj.Method && chargebackObj.Method.toLowerCase() === "card" ? "Cardholder Name" : "Account Holder Name" } </label>
                                    <h6 style={{fontWeight: 500}}>
                                        {(chargebackObj && chargebackObj.PaymentData )?  chargebackObj.PaymentData.HolderName : '-'}
                                    </h6>
                                </div>
                            </div>
                        </div>
                        }
                        <h5 className="header mb-3">Customer Information
                            <OverlayTrigger placement="top" overlay={<Tooltip>View Customer</Tooltip>}>
                                <Link style={{color: '#333333'}} to={ recordDetail?.Customer ? '/'+this.props.global.getURLEntry() +"/customer/"+ recordDetail.Customer.customerId : ''}><BiLinkExternal/></Link>
                            </OverlayTrigger>
                        </h5>
                        <div className="row mb-4">
                            <div className="col-3">
                                <label className="header">Customer</label>
                            </div>
                            <div className="col-9">
                            {(this.state.chargeback?.Customer) ? (this.state.chargeback?.Customer?.FirstName ? this.props.global.capitalizeFirstLetterOfEachWord(this.state.chargeback.Customer.FirstName) : "") + ' ' + (this.state.chargeback?.Customer?.LastName ? this.props.global.capitalizeFirstLetterOfEachWord(this.state.chargeback.Customer.LastName) : "") : '-'}
                            </div>
                        </div>

                        <h5 className="header mb-3">Processing Information</h5>
                        <div className="row mb-4">
                            <div className="col-3">
                                <label className="header">Transaction #</label>
                            </div>
                            <div className="col-9">
                            {(recordDetail && recordDetail.ResponseData ) ? recordDetail.ResponseData.transactionid : '-'}
                            </div>

                            <div className="col-3">
                                <label className="header">Payment ID</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.PaymentTransId !== null) ?  recordDetail.PaymentTransId : '-'}
                            </div>

                            <div className="col-3">
                                <label className="header">CVV Response</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.cvvresponse_text) ?  recordDetail.ResponseData.cvvresponse_text : '-'}
                            </div>

                            <div className="col-3">
                                <label className="header">AVS Response</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.avsresponse_text) ?  recordDetail.ResponseData.avsresponse_text : '-'}
                            </div>
                            <div className="col-3">
                                <label className="header">Auth Code</label>
                            </div>
                            <div className="col-9">
                                {(recordDetail && recordDetail.ResponseData && recordDetail.ResponseData.authcode) ?  recordDetail.ResponseData.authcode : '-'}
                            </div>
                            



                            <div className="col-3">
                                <label className="header">Source</label>
                            </div>
                            <div className="col-9">
                            {(recordDetail && recordDetail.Source ) ? this.props.global.capitalizeFirstLetterOfEachWord(recordDetail.Source) : '-'}
                            </div>
                        </div>

                        {(recordDetail && recordDetail.TransactionEvents && recordDetail.TransactionEvents.length > 0) &&
                        <>
                        <h5 className="header mb-3">Transaction Event History</h5>
                        <div className='timeLineRecordContainer'>
                        {recordDetail.TransactionEvents.map((record, i) => (
                            <div key={i}  className='timeLineRecordItem'>
                                <div className="timeLineRecord">&nbsp;</div>
                                <div className="timeLineRecordText"><b>{record.TransEvent}</b><br/>{this.props.global.stringDateFormat(record.EventTime)} <span className="grey">{this.props.global.stringTimeFormat(record.EventTime)} {"("+this.props.global.getTimeZone('v1')+")"}</span></div>
                                <br/>
                            </div>
                        ))}
                        </div>
                        </>
                        }

                    </div>
                
                
                </RightPanel>

            <Modal style={{textAlign: "center"}} show={this.state.acceptModalIsOpen} onHide={this.closeAcceptModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body>
                <BiQuestionMark className="icon-modal"/>
                <h5>Accept</h5>
                <p className="small">Please confirm you want to take liability for this chargeback?</p>
                <div className="row">
                    <div className="col">
                    <Button className="btn" variant="light" onClick={this.closeAcceptModal}>
                        Cancel
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button className="btn" variant="danger" onClick={this.acceptAction}>
                        Accept
                    </Button>
                    </div>
                </div>
            </Modal.Body>
            </Modal>

            <Modal show={this.state.respondModalIsOpen} onHide={this.closeRespondModal}  size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="popover-body" style={{minWidth: "100%"}}>
                <h6 className="mb-4" data-qaid="respondToChargebackModal">Respond to Chargeback</h6>


                <div className="form-floating mb-3">
                    <input className={this.state.formErrors.contactName ? "form-control input-error" : "form-control"} onChange={(e) => this.handleTextChange(e)} placeholder="Contact Name" id="contactName" value={ this.state.contactName }/>
                    <label htmlFor="contactName">Contact Name</label>
                </div>

                <div className="form-floating mb-3">
                    <IMaskInput
                        mask={this.props.global.maskValidator('email')}
                        name="email"
                        value={ this.state.contactEmail }
                        unmask={true}                        
                        onAccept={
                            (value, mask) => this.handleTextChangeMask('contactEmail', value)
                        }
                        placeholder="Email"
                        className={this.state.formErrors.contactEmail ? "form-control input-error" : "form-control"}
                        id="email"
                    />
                    <label htmlFor="email">Email</label>
                </div>

                <div className="form-floating mb-3">
                    <textarea className={this.state.formErrors.contactNotes ? "form-control input-error" : "form-control"} onChange={(e) => this.handleTextChange(e)} placeholder="Comments" id="contactNotes" style={{height: "100px"}} value={this.state.contactNotes}></textarea>
                    <label htmlFor="contactNotes">Comments</label>
                </div>

                <div className="profile-image mb-3 full-w text-center" onClick={(e)=>this.selectFile()}>
                    <a className="mb-2" onClick={(e) => this.prevent(e)} href="/">
                    <BiFileBlank /><br/>
                    <span style={{display: "block"}}  className="small-small">Select a file to upload</span>
                    </a>
                    <input multiple className="hide" ref={this.inputFile} type="file" onChange={(e) => this.handleFileRead(e)} />
                    {
                    this.state.fileSuccess.map((record, i) => (
                        <span key={i} className="small-small" style={{display: "block"}}>
                        {record} &nbsp;&nbsp;&nbsp; 
                        </span>
                    ))
                    }
                </div>
                {
                    this.state.fileErrors.map((record, i) => (
                        <div key={i} className="mb-2">
                        <span className="small-small text-danger" style={{display: "inline-block"}}>
                        {record}. File size or extension should be (pdf, jpg, png, docx, doc. 5mb maximum).<br/>
                        </span>
                        </div>
                    ))
                }

                <div className="small mb-4">
                    <b>Please be aware:</b>
                    <ul style={{paddingLeft: 0}}>
                        <li>You must supply detailed rebuttal addressing all claims and if full or partial credit was issued, please explain why only partial credit was issued.</li>
                        <li>You must supply your rebuttal and documentation no later than {chargebackObj ? this.props.global.stringDateFormat(this.props.global.addDays(chargebackObj.CreatedAt, 10)): '-'}. Failure to do so could result in forfeiture of your rights established by the Card Associations.</li>
                    </ul>
                </div>

                <div className="row">
                    <div className="col-sm-12 col-md-6 mb-3">
                        <button className="btn full-w btn-light" type="button" onClick={() => this.closeRespondModal() } data-qaid="cancelRespondButtonModal">Cancel</button>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <button className="btn full-w btn-primary" type="button" onClick={() => this.submitResponse()}>Submit</button>   
                    </div>
                </div>
            </Modal.Body>
            </Modal>

            <Modal show={this.state.previewRespondModalIsOpen} onHide={this.closePreviewRespondModal}  size="md" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className="popover-body" style={{minWidth: "100%"}}>
                <h6 className="mb-4">Chargeback Response</h6>
                <div className="form-floating mb-3">
                    <input readOnly className="form-control" placeholder="Contact Name" id="contactNamePreview" value={ this.state.contactNamePreview }/>
                    <label htmlFor="contactNamePreview">Contact Name</label>
                </div>

                <div className="form-floating mb-3">
                    <input readOnly className="form-control" placeholder="Contact Email" id="contactEmailPreview" value={ this.state.contactEmailPreview }/>
                    <label htmlFor="contactEmailPreview">Contact Email</label>
                </div>
                <div className="form-floating mb-4">
                    <textarea readOnly className="form-control" placeholder="Comments" id="contactNotesPreview" style={{height: "100px"}} value={this.state.contactNotesPreview}></textarea>
                    <label htmlFor="contactNotesPreview">Comments</label>
                </div>
                <div className="mb-4">
                    {
                        this.state.filesPreview.map((record, i) => (
                            <a key={i} href="/" onClick={(e) => reactObj.prevent(e)} className="small mb-2 no-underline" style={{display:"block"}}><BiFileBlank style={{fontSize: "18px"}}/> {record.originalName}</a>
                        ))
                    }
                </div>


                <div className="row">
                    <div className="col-sm-12">
                        <button className="btn full-w btn-light" type="button" onClick={() => this.closePreviewRespondModal() }>Close</button>
                    </div>
                </div>
            </Modal.Body>
            </Modal>

            <div>
                <TopBar>
                    <MainTopBarMenu />
                    <div className="top-bar-sub">
                        <ReportsLinks selected="chargebacks" gobackText={"Back to report"} gobackLink={"/"+PayabliStorageManager.getEntryName()+"/report/chargebacks"}/>
                    </div>
                </TopBar>


                <div className="mt-body4">
                <div className="row mb-4">
                        {(chargebackObj && chargebackObj.Method && chargebackObj.Method.toLowerCase() !== "ach") &&
                        <div className="col-12">
                            <h5>Case # {chargebackObj ? chargebackObj.CaseNumber : '-'}</h5>
                        </div>
                        }
                    </div>


                    <div className="row" style={{maxWidth: "1300px"}}>
                        <div className='col-lg-7 full-1260'>
                            <h6 className="sub-header-line mb-1 dark-grey mb-4" data-qaid="chargebackOrReturnDetails">
                                {chargebackObj && chargebackObj.Method && chargebackObj.Method.toLowerCase() === "ach" ? "ACH Return Details" : "Chargeback Details " }
                            </h6>
                            <div className="row mb-4">
                                <div className='col-sm-5'>
                                    <div className="row small mb-3">
                                        <div className="col-5 grey">Amount</div>
                                        <div className="col-7">{(chargebackObj && chargebackObj.NetAmount) ? this.props.global.numberWithCommasNegative(chargebackObj.NetAmount.toFixed(2)) : '$0.00'}</div>
                                    </div>
                                    <div className="row small mb-3">
                                        <div className="col-5 grey">Status</div>
                                        <div className="col-7"><div className="col-7">{ this.props.global.getChargebackStatus(chargebackObj?.Status) }</div></div>
                                    </div>
                                    <div className="row small mb-3">
                                        <div className="col-5 grey">Reason Code</div>
                                        <div className="col-7">{chargebackObj ? chargebackObj.ReasonCode: '-'}</div>
                                    </div>
                                    <div className="row small mb-3">
                                        <div className="col-5 grey">Reason Description</div>
                                        <div className="col-7">{chargebackObj ? chargebackObj.Reason: '-'}</div>
                                    </div>
                                    <div className="row small mb-3">
                                        <div className="col-5 grey">Customer</div>
                                        <div className="col-7">{chargebackObj && chargebackObj.Customer ? this.props.global.capitalizeFirstLetterOfEachWord(chargebackObj.Customer.FirstName + " " + chargebackObj.Customer.LastName) : '-'}</div>
                                    </div>
                                    <div className="row small mb-3">
                                        <div className="col-5 grey">Payor</div>
                                        <div className="col-7">{chargebackObj && chargebackObj.PaymentData && chargebackObj.PaymentData.HolderName ? this.props.global.capitalizeFirstLetterOfEachWord(chargebackObj.PaymentData.HolderName) : '-'}</div>                                    </div>
                                </div>
                                <div className='col-sm-7'>
                                    <div className="row small mb-3">
                                        <div className="col-5 grey">Reference #</div>
                                        <div className="col-7">{chargebackObj ? chargebackObj.ReferenceNumber: "-"}</div>
                                    </div>
                                    <div className="row small mb-3">
                                        <div className="col-5 grey">Payment ID</div>
                                        <div className="col-7">{chargebackObj ? chargebackObj.PaymentTransId: "-"}</div>
                                    </div>
                                    <div className="row small mb-3">
                                        <div className="col-5 grey">Method</div>
                                        <div className="col-7">
                                            {chargebackObj && chargebackObj.Method && chargebackObj.Method.toLowerCase() === "ach" ?
                                                <>ACH</>
                                                :
                                                chargebackObj && chargebackObj.Method ? this.props.global.capitalizeFirstLetter(chargebackObj.Method): '-'}
                                        </div>
                                    </div>
                                    <div className="row small mb-3">
                                        <div className="col-5 grey">Account Type</div>
                                        <div className="col-7">
                                            {this.getPaymentMethod(chargebackObj)}
                                        </div>
                                    </div>
                                    <div className="row small mb-3">
                                        <div className="col-5 grey">Last four</div>
                                        <div className="col-7">{chargebackObj ? this.props.chargeback.getChargebackLast4(chargebackObj) : '-'}</div>
                                    </div>
                                    <div className="row small mb-3">
                                        <div className="col-5 grey">Transaction Time</div>
                                        <div className="col-7">{chargebackObj && chargebackObj.TransactionTime ? this.props.global.stringDateFormat(chargebackObj.TransactionTime) + ' ' + this.props.global.stringTimeFormat(chargebackObj.TransactionTime) : '-'}</div>
                                    </div>
                                </div>
                            </div>

                            {chargebackObj && chargebackObj.Method && chargebackObj.Method.toLowerCase() !== "ach" &&
                                <>
                                <h6 className="sub-header-line mb-1 dark-grey">Dispute Responses <Link to={'/'} onClick={(e) => this.openRespondModal(e)} className="link-default float-end"><FaBullhorn className="icon-respond"/>  Respond to Chargeback</Link> </h6>
                                {(chargebackObj && chargebackObj.Responses  && chargebackObj.Responses.length) ?
                                <div className="report-container height-1260-auto">
                                    <table className="table table-hover table-striped mb-5">
                                        <thead>
                                            <tr>
                                                <th>Contact</th>
                                                <th>Contact Email</th>
                                                <th>Response Date</th>
                                                <th className="text-right">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                            chargebackObj.Responses.map((record, i) => (
                                            <tr key={i}>
                                                <td>{record.ContactName}</td>
                                                <td>{record.ContactEmail}</td>
                                                <td>{this.props.global.stringDateFormat(record.CreatedAt)}</td>
                                                <td className="text-right"><a href="/" onClick={(e) => this.openPreviewRespondModal(e, record)}>View</a></td>
                                            </tr>
                                            ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :  <div className="card-v2 mb-3 info-message-card"><BiInfoCircle style={{fontSize: "20px"}}/> Records not found</div>
                                }
                                </>
                            }
                        </div>
                        <div className='col-lg-5 dashboard-items full-1260' style={{marginTop: "35px"}}>
                            <div className="row">
                                <div className="col-sm-4 mb-5">
                                    <div className="dashboard-color-circle green">
                                        <BiDollar/>
                                    </div>
                                    <label>Amount</label><br/>
                                    <b className="small">{(chargebackObj && chargebackObj.NetAmount) ? this.props.global.numberWithCommasNegative(chargebackObj.NetAmount.toFixed(2)) : '$0.00'}</b>
                                </div>

                                <div className="col-sm-4 mb-5">
                                {chargebackObj && chargebackObj.Method && chargebackObj.Method.toLowerCase() === "ach" ?
                                    <>
                                    <div className="dashboard-color-circle teal">
                                    <BiUndo/>
                                    </div>
                                    <label>Status</label><br/>
                                    <b className="small">Returned</b>
                                    </>
                                :
                                <>
                                {(chargebackObj && chargebackObj.Status !== null) &&
                                    <>
                                        {chargebackObj.Status === 0 &&
                                            <>
                                            <div className="dashboard-color-circle lightBlue">
                                            <BiCheck/>
                                            </div>
                                            <label>Status</label><br/>
                                            <b className="small">Open</b>
                                            </>
                                        }

                                        {chargebackObj.Status === 1 &&
                                            <>
                                            <div className="dashboard-color-circle bg-warning">
                                            <BiTimer />
                                            </div>
                                            <label>Status</label><br/>
                                            <b className="small">Pending</b>
                                            </>
                                        }

                                        {chargebackObj.Status === 2 &&
                                            <>
                                            <div className="dashboard-color-circle bg-success">
                                            <BiCheck/>
                                            </div>
                                            <label>Status</label><br/>
                                            <b className="small">Closed-Won</b>
                                            </>
                                        }

                                        {chargebackObj.Status === 3 &&
                                            <>
                                            <div className="dashboard-color-circle bg-danger">
                                            <BiCheck/>
                                            </div>
                                            <label>Status</label><br/>
                                            <b className="small">Closed-Lost</b>
                                            </>
                                        }
                                    </>
                                 }
                                 </>
                                }
                                        
                                </div>
                                {chargebackObj && chargebackObj.Method && chargebackObj.Method.toLowerCase() !== "ach" &&
                                <div className="col-sm-4 mb-5">
                                    <div className="dashboard-color-circle orange">
                                        <BiTimeFive/>
                                    </div>
                                    <label>Reply by</label><br/>
                                    <b className="small">{chargebackObj ? this.props.global.stringDateFormatV3(this.props.global.addDays(chargebackObj.CreatedAt, 10)): '-'}</b>
                                </div>
                                }

                            </div>
                            <div className="row mb-5">
                                {chargebackObj && chargebackObj.Method && chargebackObj.Method.toLowerCase() !== "ach" &&
                                <div className="col-sm-6 mb-3">
                                    <button onClick={(e) => this.openRespondModal(e)} className="btn full-w bordered-2"><BiMailSend className="mb-2" style={{fontSize: "30px"}}/> <br/>Respond to <br/>Chargeback</button>
                                </div>
                                }

                                <div className="col-sm-6 mb-3">
                                    <button onClick={(e) => this.openRightPanel(e)} className="btn full-w bordered-2"><BiReceipt className="mb-2" style={{fontSize: "30px"}}/> <br/>See original <br/>transaction</button>
                                </div>

                                <div className="col-sm-6 mb-3">
                                    {(customerId>0) && 
                                        <button onClick={(e) => this.goToPayHistory(e)} className="btn full-w bordered-2"><BiHistory className="mb-2" style={{ fontSize: "30px" }} /> <br />See customer <br />pay history</button>
                                    }
                                </div>


                                { (chargebackObj && chargebackObj.Status !== 2 && chargebackObj.Status !== 3 && chargebackObj.Method && chargebackObj.Method.toLowerCase() !== "ach") &&
                                <div className="col-sm-6 mb-3">
                                    <button onClick={(e) => this.openAcceptModal(e)} className="btn full-w bordered-2"><BiShieldX className="mb-2" style={{fontSize: "30px"}}/> <br/>Accept <br/>Liability</button>
                                </div>
                                }

                                { (chargebackObj && chargebackObj.Status === 3 && chargebackObj.Method && chargebackObj.Method.toLowerCase() !== "ach") &&
                                <div className="col-sm-6 mb-3">
                                    <button className="btn full-w bordered-2 btn-danger-v2"><BiShieldX className="mb-2" style={{fontSize: "30px"}}/> <br/>Accepted <br/>Liability</button>
                                </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <ToastContainer transition={Bounce} />
            </Layout>
        )
    }
}

export { ViewChargeback };