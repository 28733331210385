import React from "react";
import { inject, observer } from "mobx-react";
import { Layout } from "../../../components/Layout";
import { TopBar } from "../../../components/TopBar";
import { MainTopBarMenu } from "../../../components/MainTopBarMenu";
import { Link } from "react-router-dom";
import { PayabliStorageManager } from "../../../api/localStorageManager";

import { BiChevronLeft, BiListUl, BiMessageSquareError } from "react-icons/bi";
import { toast, ToastContainer, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { InvoiceForm } from "./form/InvoiceForm";
import InvoicePreview from "./preview/InvoicePreview";
import ModalConfirmation from "./ModalConfirmation";

import { withRouter } from "react-router";
import { Dropdown, DropdownButton, Modal, Button } from "react-bootstrap";

@inject("store", "global", "entry", "invoice", "vTerminal")
@observer
class InvoiceBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      settings: {},
      invoiceData: null, // for data loaded when a invoice is Edit
      paylinkData: null, // for data loaded when a invoice is Edit
      modalConfirmationOpen: false,
      previewShown: true,
      infoModalIsOpen: false,
    };

    this.handleToggleModal = this.handleToggleModal.bind(this);
    this.handleSaveDraft = this.handleSaveDraft.bind(this);
    this.handleTogglePreview = this.handleTogglePreview.bind(this);
    this.openInfoModal = this.openInfoModal.bind(this);
    this.closeInfoModal = this.closeInfoModal.bind(this);
    this.exportInvoiceFile = this.exportInvoiceFile.bind(this);
    this.saveAndExportInvoiceFile = this.saveAndExportInvoiceFile.bind(this);
    this.getMainActionButtons = this.getMainActionButtons.bind(this);
  }

  componentDidMount() {
    this.props.global.setLoading(true);
    this.loadInvoiceData().finally(() => {
      this.props.global.setLoading(false);
    });
  }

  async saveAndExportInvoiceFile() {
    const invoiceStatus = this.props.invoice.status;
    if (invoiceStatus === 4 || invoiceStatus === 2) {
      this.exportInvoiceFile();
    } else {
      this.props.global.setLoading(true);
      await this.props.invoice
        .updateInvoice(this.props.invoice.status)
        .then((res) => {
          this.exportInvoiceFile();
          this.props.global.setLoading(false);
          toast.success("Your invoice was saved successfully!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "toast-success-container",
          });
        })
        .catch((error) => {
          this.props.global.setLoading(false);
          let errorMessage =
            error.response && error.response.data.responseText
              ? error.response.data.responseText
              : "Something is Wrong!";
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            className: "toast-error-container",
          });
        });
    }
  }

  async loadInvoiceData() {
    const encryptStorage = PayabliStorageManager.getEncryptedLocalStorage();
    const { pEntry } = encryptStorage.getItem(
      this.props.global.getURLEntry() + "_pEntry"
    );
    this.props.invoice.reset();
    this.props.global.setLoading(true);

    const invoiceId = this.props.match.params.id;
    // load Invoice data
    if (invoiceId) {
      this.props.invoice.updateStatusInvoice(0);

      const data = await this.props.invoice.loadInvoice(invoiceId);
      await this.loadDefaultSettings(pEntry, data.paylinkId);

      this.setState({ invoiceData: data });
    } else {
      this.setState({ invoiceData: null });
      await this.loadDefaultSettings(pEntry);
    }
  }

  openInfoModal(info) {
    this.setState({ infoModalIsOpen: true, infoMessageInModal: info });
  }

  closeInfoModal() {
    this.setState({ infoModalIsOpen: false });
  }

  handleToggleModal() {
    if (
      !this.props.store.hasCards &&
      !this.props.store.hasECheck &&
      !this.props.store.hasWallets.applePay &&
      !this.props.store.hasWallets.googlePay
    ) {
      this.openInfoModal("You must select at least one payment method");
    } else {
      this.setState({
        modalConfirmationOpen: !this.state.modalConfirmationOpen,
      });
    }
  }

  handleTogglePreview() {
    this.setState({ previewShown: !this.state.previewShown });
  }

  async handleSaveDraft() {
    try {
      this.props.global.setLoading(true);
      // 0: draft
      if (this.props.invoice.status === "new") {
        await this.props.invoice.saveInvoice(0);
      } else {
        await this.props.invoice.updateInvoice(0);
      }

      toast.success("Your draft invoice was saved successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "toast-success-container",
      });

      this.props.invoice.updateStatusInvoice(0);
    } catch (e) {
      if (e.response) {
        this.props.global.setLoading(false);
        return toast.error(e.response.data.responseText, {
          position: toast.POSITION.BOTTOM_RIGHT,
          className: "toast-error-container",
        });
      }

      toast.error(e.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        className: "toast-error-container",
      });
    }

    this.props.global.setLoading(false);
  }

  async loadDefaultSettings(pEntry, paylinkId) {
    const paypointSettings = await this.props.entry.getPaypointSettings(pEntry);
    this.props.store.setWalletsEnabled(paypointSettings.forWallets || []);
    const { forInvoices } = paypointSettings;
    const valueLoaded = {};

    if (forInvoices !== null) {
      Object.values(forInvoices).forEach(({ key, value }) => {
        let valueParsed = value;
        if (value === "true") valueParsed = true;
        if (value === "false") valueParsed = false;
        if (value === "null") valueParsed = null;
        if (key === "dueDateCustom") valueParsed = new Date(value);

        valueLoaded[key] = valueParsed;
      });
    }

    if (paylinkId && this.props.invoice.status !== 4) {
      const dataPaylink = await this.props.invoice.getPaymentLinkData(
        paylinkId
      );
      if (dataPaylink.responseData) {
        let paylinkSettings = dataPaylink.responseData;
        valueLoaded.paylinkHeader =
          paylinkSettings.PageContent && paylinkSettings.PageContent.page.header
            ? paylinkSettings.PageContent.page.header
            : valueLoaded.paylinkHeader;
        valueLoaded.paylinkDescription =
          paylinkSettings.PageContent &&
          paylinkSettings.PageContent.page.description
            ? paylinkSettings.PageContent.page.description
            : valueLoaded.paylinkDescription;
        valueLoaded.footerNote =
          paylinkSettings.PageContent && paylinkSettings.PageContent.notes.value
            ? paylinkSettings.PageContent.notes.value
            : valueLoaded.footerNote;

        let pMethods = paylinkSettings.PageContent.paymentMethods.methods;
        let store = this.props.store;
        setTimeout(() => {
          store.setPaymentMethods(pMethods);
        }, 100);
      }
    }

    this.setState({ settings: valueLoaded });
    this.props.invoice.saveDefaultSettings(valueLoaded);

    const res = await this.props.entry.getEntryFromApi(pEntry);
    if (res.responseData && res.responseData.Paypoint) {
      this.props.invoice.setPaypointData({
        logo: res.responseData.EntryLogo,
        ...res.responseData.Paypoint,
      });
    }
  }

  async exportInvoiceFile() {
    let invoiceId = await this.props.invoice.invoiceId,
      invoiceNumber = await (this.props.invoice.invoiceDetails &&
      this.props.invoice.invoiceDetails.invoiceNumber
        ? this.props.invoice.invoiceDetails.invoiceNumber
        : "");
    this.props.invoice.exportInvoiceFile(invoiceId, invoiceNumber);
  }

  getMainActionButtons() {
    const btnActionDisabled = !this.props.invoice.isBtnActionEnabled;
    const invoiceAction = this.props.invoice.invoiceActionSelected.action;
    const buttonActionText =
      invoiceAction === "chargeCustomer" ? "Charge Invoice" : "Send Invoice";

    return (
      <>
        {
          <span className="bottom-btn">
            <button
              id="btn-action-invoice-builder"
              className="btn btn-success singleButton"
              disabled={btnActionDisabled}
              onClick={this.handleToggleModal}
            >
              {buttonActionText}
            </button>
          </span>
        }
      </>
    );
  }

  render() {
    const invoiceAction = this.props.invoice.invoiceActionSelected.action;
    const buttonActionText =
      invoiceAction === "chargeCustomer" ? "Charge Invoice" : "Send Invoice";
    const btnActionDisabled = !this.props.invoice.isBtnActionEnabled;

    const invoiceStatus = this.props.invoice.status;
    const isVisibleSaveDraft = invoiceStatus === "new" || invoiceStatus === 0;

    const invoicePartiallyPaid = invoiceStatus === 2;
    const invoiceCompleted = invoiceStatus === 4;

    let classesForm = this.state.previewShown
      ? "col-md-5 full-1260"
      : "col-md-6";
    if (invoiceCompleted || invoicePartiallyPaid) classesForm += " hide";

    let classesPreview =
      invoiceCompleted || invoicePartiallyPaid ? "" : "col-md-7 full-1260";
    if (!this.state.previewShown) classesPreview += " hide";

    const btnPreviewText = this.state.previewShown
      ? "Hide Preview"
      : "Show Preview";
    const btnSaveDownloadText =
      invoiceCompleted || invoicePartiallyPaid ? "Download" : "Save & Download";

    return (
      <Layout {...this.props}>
        <div id="invoicesBuilder">
          <TopBar>
            <MainTopBarMenu />
            <div className="top-bar-sub">
              <div className="row datatable-actions">
                <div className="col-5" style={{ alignSelf: "center" }}>
                  <Link
                    to={
                      "/" +
                      PayabliStorageManager.getEntryName() +
                      "/report/invoices"
                    }
                    className="btn btn-light text-transform-normal"
                  >
                    <BiChevronLeft /> Go back{" "}
                    <span className="hide-sm-sm">to invoice list</span>
                  </Link>
                </div>
                <div className="col-7 text-right">
                  <div className="d-none show-md">
                    {this.getMainActionButtons()}
                    <DropdownButton
                      menuAlign="right"
                      title={
                        <div>
                          <BiListUl /> Actions
                        </div>
                      }
                      size="sm"
                      variant="default"
                      className="DropdownButtonV2"
                    >
                      {isVisibleSaveDraft && (
                        <Dropdown.Item onClick={this.handleSaveDraft}>
                          Save Draft
                        </Dropdown.Item>
                      )}
                      {!invoiceCompleted && (
                        <>
                          <Dropdown.Item onClick={this.handleTogglePreview}>
                            {btnPreviewText}
                          </Dropdown.Item>
                        </>
                      )}
                      {!isVisibleSaveDraft && this.props.invoice.invoiceId && (
                        <Dropdown.Item onClick={this.saveAndExportInvoiceFile}>
                          {btnSaveDownloadText}
                        </Dropdown.Item>
                      )}
                    </DropdownButton>
                  </div>
                  <div className="hide-md">
                    {isVisibleSaveDraft && (
                      <button
                        className="btn btn-light"
                        type="button"
                        onClick={this.handleSaveDraft}
                      >
                        Save Draft
                      </button>
                    )}
                    {!invoiceCompleted && !invoicePartiallyPaid && (
                      <button
                        className="btn btn-light"
                        type="button"
                        onClick={this.handleTogglePreview}
                      >
                        {btnPreviewText}
                      </button>
                    )}
                    {!isVisibleSaveDraft && this.props.invoice.invoiceId && (
                      <button
                        className="btn btn-light"
                        type="button"
                        onClick={this.saveAndExportInvoiceFile}
                      >
                        {btnSaveDownloadText}
                      </button>
                    )}
                    {!invoiceCompleted && !invoicePartiallyPaid && (
                      <>{this.getMainActionButtons()}</>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </TopBar>

          <div className="body-builder">
            <div className="row justify-content-center">
              <div className={classesForm}>
                <div id="wrapper-invoice-form">
                  <InvoiceForm
                    invoiceDataLoaded={this.state.invoiceData}
                    settings={this.state.settings}
                  />
                </div>
              </div>

              <div className={classesPreview}>
                <div
                  id="wrapper-invoice-preview"
                  className={
                    invoiceStatus === 4 ? "wrapper-invoice-preview-clean" : ""
                  }
                >
                  <InvoicePreview />
                </div>
              </div>
            </div>
          </div>

          <ModalConfirmation
            open={this.state.modalConfirmationOpen}
            close={this.handleToggleModal}
          />

          <Modal
            style={{ textAlign: "center" }}
            show={this.state.infoModalIsOpen}
            onHide={this.closeInfoModal}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body>
              <BiMessageSquareError className="icon-modal" />
              <h5>Info</h5>
              <p className="small">{this.state.infoMessageInModal}</p>
              <Button className="btn" onClick={this.closeInfoModal}>
                Close
              </Button>
            </Modal.Body>
          </Modal>

          <ToastContainer transition={Bounce} />
        </div>
      </Layout>
    );
  }
}

export default withRouter(InvoiceBuilder);
